import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './slice/auth';
import contact from './slice/contact';
import profile from './slice/profile';

const rootReducer = combineReducers({
  auth,
  contact,
  profile
});
const store = configureStore({
  reducer: rootReducer
});
export default store;
