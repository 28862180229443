import { Link } from 'react-router-dom';

export default function WealthServices() {
  // const [activeIndex, setActiveIndex] = useState(1);
  // const handleOnClick = (index) => {
  //   setActiveIndex(index);
  // };
  const wealthServiceData = [
    {
      title: 'Financial literacy ',
      icon: 'icon-12'
    },
    {
      title: 'Estate Planning ',
      icon: 'icon-13'
    },
    {
      title: 'Credit monitoring',
      icon: 'icon-14'
    },
    {
      title: 'Real Estate',
      icon: 'icon-15'
    },
    {
      title: 'Financial planning',
      icon: 'icon-16'
    },
    {
      title: ' Insurance ',
      icon: 'icon-17'
    }
  ];
  return (
    <>
      <section className="service-section pt_20 pb_90">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h6>Our Services</h6>
            <h2>
              Wealth Managment <br />
              Services
            </h2>
          </div>
          <div className="row clearfix">
            {wealthServiceData?.map((item, index) => {
              if (index >= 4) {
                return (
                  <div key={index} className="col-lg-3 col-md-6 col-sm-12 service-block">
                    <div
                      className="service-block-one wow fadeInRightBig animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms">
                      <div
                        className="service-block-one wow fadeInUp animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms">
                        <div className="inner-box">
                          <div className="shape"></div>
                          <div className="icon-box">
                            <i className={item?.icon}></i>
                          </div>
                          <h4>
                            <Link href="/service-details">{item?.title}</Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index} className="col-lg-3 col-md-6 col-sm-12 service-block">
                    <div
                      className="service-block-one wow fadeInLeftBig animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms">
                      <div
                        className="service-block-one wow fadeInUp animated"
                        data-wow-delay="00ms"
                        data-wow-duration="1500ms">
                        <div className="inner-box">
                          <div className="shape"></div>
                          <div className="icon-box">
                            <i className={item?.icon}></i>
                          </div>
                          <h4>
                            <Link href="/service-details">{item?.title}</Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    </>
  );
}
