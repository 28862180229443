import React from 'react';
import image from 'assets/images/servicesDetails/wealth-management.png';
import shape from 'assets/images/shape/shape-11.png';

export default function AboutWealth() {
  return (
    <section className="about-style-two pt_120 pb_120">
      <div className="wow fadeInUpBig animated" data-wow-delay="00ms" data-wow-duration="1500ms">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_three">
                <div className="content-box mr_110">
                  <div className="sec-title mb_20">
                    <h6>About Wealth Managment</h6>
                    <h2>Unlock personalized wealth management solutions.</h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      We specialize in comprehensive wealth management services, offering a spectrum
                      of financial solutions to our esteemed clients. Our expertise spans diverse
                      domains, encompassing strategic investment planning, real estate portfolio
                      management, and meticulous credit monitoring. With a steadfast commitment to
                      excellence, we provide informed guidance to navigate the complexities of
                      wealth management, ensuring the financial well-being and prosperity of our
                      clients
                    </p>
                    {/* <ul className="list-style-one clearfix">
                    <li>Improving all types of opportunities</li>
                    <li>Quick solutions for daily problems</li>
                    <li>Community that grows larger</li>
                  </ul> */}
                  </div>
                  {/* <div className="btn-box">
                  <Link href="/about" className="theme-btn btn-one">
                    Discover More
                  </Link>
                </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_two">
                <div className="image-box">
                  <div className="image-shape">
                    <div
                      className="shape-3"
                      style={{
                        backgroundImage: `url(${shape})`
                      }}></div>
                    <div
                      className="shape-4"
                      style={{
                        backgroundImage: `url(${shape})`
                      }}></div>
                  </div>
                  <figure className="grow image">
                    <img src={image} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
