import { Link } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import bannerNetWorth from '../../../assets/images/banner/banner-net-worth.png';
import bannerNetWorthMobile from '../../../assets/images/mobileBanners/net-worth-services.png';
import bannerWealth from '../../../assets/images/banner/banner-wealth.png';
import bannerWealthMobile from '../../../assets/images/mobileBanners/wealth-management.png';
import bannerTransfer from '../../../assets/images/banner/banner-transfer.png';
import bannerTransferMobile from '../../../assets/images/banner/banner-transfer-mobile.png';
import bannerApp from '../../../assets/images/banner/banner-app.png';
import bannerAppMobile from '../../../assets/images/banner/banner-app-mobile.png';
import shapeImage from '../../../assets/images/shape/shape-2.png';
import { useResponsive } from 'component/useResponsive';

const swiperOptions = {
  modules: [Autoplay, Pagination, Navigation],
  slidesPerView: 1,
  spaceBetween: 0,
  autoplay: {
    delay: 25000,
    disableOnInteraction: false
  },
  // loop: true,
  // Navigation
  navigation: {
    nextEl: '.h1n',
    prevEl: '.h1p'
  },

  // Pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  }
};

export default function Banner() {
  const { screenType } = useResponsive();
  return (
    <>
      <section className="banner-section p_relative">
        <Swiper {...swiperOptions} className="banner-carousel">
          <SwiperSlide className="slide-item p_relative">
            {screenType === 'mobile' && (
              <div
                className="bg-layer"
                style={{
                  backgroundImage: `url(${bannerAppMobile})`
                }}></div>
            )}
            {screenType !== 'mobile' && (
              <div
                className="bg-layer"
                style={{
                  backgroundImage: `url(${bannerApp})`
                }}></div>
            )}
            <div className="pattern-layer">
              <div className="pattern-2" style={{ backgroundImage: `url(${shapeImage})` }}></div>
            </div>
            <div className="auto-container">
              <div className="content-box">
                <h2>
                  Download the Silverback <span>Mobile banking App</span>
                </h2>
                <p>An app for convenient and secure access to all your financial services.</p>
                <div className="btn-box">
                  <Link href="/" className="theme-btn btn-one">
                    Download Apps Now
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-item p_relative">
            {screenType === 'mobile' && (
              <div
                className="bg-layer"
                style={{
                  backgroundImage: `url(${bannerNetWorthMobile})`
                }}></div>
            )}
            {screenType !== 'mobile' && (
              <div
                className="bg-layer"
                style={{
                  backgroundImage: `url(${bannerNetWorth})`
                }}></div>
            )}
            <div className="pattern-layer">
              <div className="pattern-2" style={{ backgroundImage: `url(${shapeImage})` }}></div>
            </div>
            <div className="auto-container">
              <div className="content-box">
                <h2>
                  Manage and grow your<span> net worth </span>
                </h2>
                <p>
                  {` Manage your net worth effortlessly with Silverback's`} <br />
                  comprehensive financial tools.
                </p>
                <div className="btn-box">
                  <Link href="/" className="theme-btn btn-one">
                    Open Account
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-item p_relative">
            {screenType === 'mobile' && (
              <div
                className="bg-layer"
                style={{
                  backgroundImage: `url(${bannerWealthMobile})`
                }}></div>
            )}
            {screenType !== 'mobile' && (
              <div
                className="bg-layer"
                style={{
                  backgroundImage: `url(${bannerWealth})`
                }}></div>
            )}
            <div className="pattern-layer">
              <div className="pattern-2" style={{ backgroundImage: `url(${shapeImage})` }}></div>
            </div>
            <div className="auto-container">
              <div className="content-box">
                <h2>
                  Unlock personalized <span>wealth management</span> solutions.
                </h2>
                <p>
                  Unlock personalized wealth management solutions tailored to your financial goals
                  with Silverback
                </p>
                <div className="btn-box">
                  <Link href="/" className="theme-btn btn-one">
                    Open Account
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide-item p_relative">
            {screenType === 'mobile' && (
              <div
                className="bg-layer"
                style={{
                  backgroundImage: `url(${bannerTransferMobile})`
                }}></div>
            )}
            {screenType !== 'mobile' && (
              <div
                className="bg-layer"
                style={{
                  backgroundImage: `url(${bannerTransfer})`
                }}></div>
            )}
            <div className="pattern-layer">
              <div className="pattern-2" style={{ backgroundImage: `url(${shapeImage})` }}></div>
            </div>
            <div className="auto-container">
              <div className="content-box">
                <h2>
                  Easily transfer money <span>securely and swiftly.</span>
                </h2>
                <p>
                  {` Transfer your money securely and swiftly with Silverback's seamless money transfer
                  services.`}
                </p>
                <div className="btn-box">
                  <Link href="/" className="theme-btn btn-one">
                    Open Account
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <div className="owl-nav">
            <button type="button" className="owl-prev h1p">
              <span className="icon-3"></span>
            </button>
            <button type="button" className="owl-next h1n">
              <span className="icon-4"></span>
            </button>
          </div>
        </Swiper>
      </section>
    </>
  );
}
