import React, { useEffect } from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/money-transfer-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/money-transfer.png';
import contentBgImage from 'assets/images/servicesDetails/money-transfer.png';
import ServiceDetails from './serviceDetails';
import { useResponsive } from 'component/useResponsive';
export default function MoneyTransferService() {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const masterCardFeatureData = {
    title: 'Money Transfer/Internal Transfer',
    backgroundImage: screenType === 'mobile' ? mobileImage : desktopImage,
    contentBgImage: contentBgImage,
    content: [
      {
        title: 'Instant Transfers',
        desc: 'Internal money transfers ensure immediate funds availability, facilitating quick transactions.'
      },
      {
        title: 'Cost-Efficient',
        desc: 'Transfer money internally without additional fees, saving on transaction costs.'
      },
      {
        title: 'Simplified Process',
        desc: ' Internal transfers offer a straightforward and user-friendly process, enhancing overall convenience.'
      }
    ]
  };

  return (
    <>
      <TopHeader
        backgroundImage={masterCardFeatureData?.backgroundImage}
        title={masterCardFeatureData?.title}
      />
      <ServiceDetails servicesData={masterCardFeatureData} />
      {/* <Subscribe /> */}
    </>
  );
}
