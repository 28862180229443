import React from 'react';
import image from 'assets/images/servicesDetails/rewards.png';
import shape from 'assets/images/shape/shape-11.png';

export default function RewardsDetail() {
  return (
    <section className="about-style-two pt_120 pb_120">
      <div className="wow fadeInUpBig animated" data-wow-delay="00ms" data-wow-duration="1500ms">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_three">
                <div className="content-box mr_110">
                  <div className="sec-title mb_20">
                    <h6>CASHBACK REWARD</h6>
                    <h2>
                      Make Every Purchase Rewarding.<span>1% CashBack</span>
                      Every Time.
                    </h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      At Silverback, enjoy 1% cashback on all credit purchases made with your card,
                      along with a plethora of exclusive rewards tailored just for you.
                    </p>
                    <ul className="list-style-one clearfix">
                      <li>Every swipe on SilverBack Debit card earns you cashback.</li>
                      <li>
                        Simply use your card and watch your cashback accumulate. No need to track
                        points or categories.
                      </li>
                      <li>Redeem your cashback. </li>
                    </ul>
                  </div>
                  {/* <div className="btn-box">
                  <Link href="/about" className="theme-btn btn-one">
                    Discover More
                  </Link>
                </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_two">
                <div className="image-box">
                  <div className="image-shape">
                    <div
                      className="shape-3"
                      style={{
                        backgroundImage: `url(${shape})`
                      }}></div>
                    <div
                      className="shape-4"
                      style={{
                        backgroundImage: `url(${shape})`
                      }}></div>
                  </div>
                  <figure className="grow image">
                    <img src={image} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
