import SVButton from 'component/SVButton';
import SVInput from 'component/SVInput';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { sendOtp, verifyOtpApi } from 'store/slice/auth';
import { isObjectEmpty } from 'utils/appConstant';
import './index.css';

export default function Otp() {
  const [otpForm, setOtpForm] = useState({});
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(45);

  const getData = secureLocalStorage.getItem('userData');
  const userData = JSON.parse(getData);
  console.log('timeLeft', timeLeft);

  const resendApi = () => {
    if (timeLeft > 45 || timeLeft === undefined) {
      setTimeLeft(45);
    } else {
      const intervalId = setInterval(() => {
        setTimeLeft((t) => {
          if (t > 0) {
            return t - 1;
          } else {
            t = 0;
          }
        });
      }, 1000);
      console.log(intervalId);
      return () => clearInterval(intervalId);
    }
    var data = {
      email: userData?.email
    };
    dispatch(sendOtp(data));
  };

  const verifyOtp = () => {
    if (isObjectEmpty(otpForm)) {
      setFormError((formError) => ({
        ...formError,
        otp: 'OTP field is required.'
      }));
      return;
    }
    if (otpForm?.otp.length < 4) {
      setFormError((formError) => ({
        ...formError,
        otp: 'Please enter 4 digit OTP.'
      }));
      return;
    }
    var data = {
      email: userData?.email,
      otp: otpForm?.otp
    };
    dispatch(verifyOtpApi(data, navigate));
  };

  return (
    <div>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <h5>OTP Verification </h5>
      </div>
      <div style={{ textAlign: 'center', marginTop: 5 }}>
        <p style={{ color: 'grey', fontSize: 14 }}>
          Please enter the 4 digit code send to {userData?.email}
        </p>
      </div>
      <div className="mt-4">
        <SVInput
          type="text"
          name="otp"
          icon="teenyicons:otp-outline"
          value={otpForm?.otp}
          placeholder="Enter OTP 4-digit code"
          maxLength={4}
          onChange={(e) => {
            const numeric = /^[0-9\b]+$/;
            if (e.target.value === '' || numeric.test(e.target.value)) {
              setOtpForm((otpForm) => ({
                ...otpForm,
                otp: e.target.value
              }));
              if (e) {
                setFormError((formError) => ({
                  ...formError,
                  otp: ''
                }));
              }
            }
          }}
          formError={formError?.otp}
        />
      </div>
      <div className="mt-3" style={{ textAlign: 'center' }}>
        <p style={{ color: 'grey', fontSize: 14 }}>{`Did'nt receive code?`}</p>
        <button disabled={timeLeft > 0 && timeLeft !== 45 ? true : false} onClick={resendApi}>
          <p style={{ color: 'grey', fontSize: 18, fontFamily: 'bold' }}>Resend OTP</p>
        </button>
      </div>
      {timeLeft !== 0 && timeLeft != 45 && timeLeft != undefined && (
        <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-2">
          <div
            className="test rounded-circle "
            style={{
              justifyContent: 'center',
              color: 'white',
              alignItems: 'center',
              display: 'flex'
            }}>
            {timeLeft}
          </div>
        </div>
      )}

      <div className="mt-4">
        <SVButton title={'Submit '} onClick={verifyOtp} />
      </div>
    </div>
  );
}
