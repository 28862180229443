import { Link } from 'react-router-dom';
import mobileImage1 from '../../../assets/images/resource/mockup-3.png';
import mobileImage2 from '../../../assets/images/resource/mockup-4.png';
import googleImage from '../../../assets/images/icons/icon-2.png';
import appleImage from '../../../assets/images/icons/icon-3.png';
export default function App() {
  return (
    <>
      <section
        className="apps-section pt_120 pb_120 "
        style={{
          // backgroundImage: `url(${backgroundImage1})`,
          backgroundSize: 'cover'
        }}>
        <div
          className="wow fadeInLeftBig animated"
          data-wow-delay="00ms"
          data-wow-duration="1500ms">
          <div
            className="light-icon"
            // style={{ backgroundImage: `url(${ShapeImage1})` }}
          ></div>
          <div className="auto-container">
            <div className="inner-container">
              <div className="shape">
                <div
                  className="shape-1"
                  // style={{ backgroundImage: `url(${backgroundImage})` }}
                ></div>
                <div
                  className="shape-2"
                  // style={{ backgroundImage: `url(${ShapeImage2})` }}
                ></div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                  <div className="image-box">
                    <figure className="image image-1">
                      <img src={mobileImage1} alt="" />
                    </figure>
                    <figure className="image image-2">
                      <img src={mobileImage2} alt="" />
                    </figure>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                  <div className="content-box">
                    <div className="sec-title mb_20">
                      <h6>Download Now</h6>
                      <h2>Get the Fastest and Most Secure Mobile Banking </h2>
                    </div>
                    <div className="text-box mb_50">
                      <p>
                        The SilverBack app makes it easy to track your spending, pay bills, and
                        invest on the go.{' '}
                      </p>
                    </div>
                    <div className="btn-box">
                      <Link href="/" className="play-store mr_20">
                        <img src={googleImage} alt="" />
                        <span>get it on</span>
                        Google Play
                      </Link>
                      <Link href="/" className="play-store">
                        <img src={appleImage} alt="" />
                        <span>Download on the</span>
                        App Store
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
