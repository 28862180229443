import React, { useEffect } from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/wealth-insight-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/wealth-management.png';
import contentBgImage from 'assets/images/servicesDetails/wealth-management.png';
import ServiceDetails from './serviceDetails';
import { useResponsive } from 'component/useResponsive';
export default function WealthManagmentService() {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const masterCardFeatureData = {
    title: 'Wealth Managment Services ',
    backgroundImage: screenType === 'mobile' ? mobileImage : desktopImage,
    contentBgImage: contentBgImage,
    content: [
      {
        title: 'Holistic Financial Planning',
        desc: ' Wealth management services provide comprehensive financial planning, integrating investments, estate planning, and tax strategies for a complete approach.'
      },
      {
        title: 'Diversified Portfolio Management',
        desc: "Services focus on optimizing and diversifying investment portfolios to maximize returns and mitigate risks, aligning with clients' financial goals."
      },
      {
        title: 'Tailored Advisory Services',
        desc: 'Wealth management offers personalized advice, addressing individual financial needs, and providing ongoing guidance for informed decision-making and wealth growth.'
      }
    ]
  };

  return (
    <>
      <TopHeader
        backgroundImage={masterCardFeatureData?.backgroundImage}
        title={masterCardFeatureData?.title}
      />
      <ServiceDetails servicesData={masterCardFeatureData} />
      {/* <Subscribe /> */}
    </>
  );
}
