import { Link } from 'react-router-dom';
import pressImage from 'assets/images/news/news-4.jpg';
export default function PressReleaseList() {
  return (
    <>
      <div>
        {/* news-style-two */}
        <section className="news-style-two blog-grid pt_120 pb_120">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-6 col-sm-12 news-block">
                <div
                  className="news-block-two wow fadeInUp animated"
                  data-wow-delay="00ms"
                  data-wow-duration="1500ms">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="/press-detail">
                        <img src={pressImage} alt="" />
                      </Link>
                    </figure>
                    <div className="lower-content">
                      <div className="post-date">
                        <h4>
                          10<span>Jan</span>
                        </h4>
                      </div>
                      <h3>
                        <Link to="/press-detail">
                          Self-Guided Driving & Tours Walk Of Greater City
                        </Link>
                      </h3>
                      <p>
                        Amet minim mollit duis sit enim aliqua dolor do amet officia. Pellentesque
                        etiam niss...
                      </p>
                      <ul className="post-info">
                        <li>
                          <i className="icon-32"></i>
                          <Link to="/press-detail">Admin</Link>
                        </li>
                        <li>
                          <i className="icon-33"></i>0 Comment
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 news-block">
                <div
                  className="news-block-two wow fadeInUp animated"
                  data-wow-delay="300ms"
                  data-wow-duration="1500ms">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="/press-detail">
                        <img src={pressImage} alt="" />
                      </Link>
                    </figure>
                    <div className="lower-content">
                      <div className="post-date">
                        <h4>
                          09<span>Jan</span>
                        </h4>
                      </div>
                      <h3>
                        <Link to="/press-detail">Assistance Homes Properties Real Estate</Link>
                      </h3>
                      <p>
                        Amet minim mollit duis sit enim aliqua dolor do amet officia. Pellentesque
                        etiam niss...
                      </p>
                      <ul className="post-info">
                        <li>
                          <i className="icon-32"></i>
                          <Link to="/press-detail">Admin</Link>
                        </li>
                        <li>
                          <i className="icon-33"></i>3 Comment
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 news-block">
                <div
                  className="news-block-two wow fadeInUp animated"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms">
                  <div className="inner-box">
                    <figure className="image-box">
                      <Link to="/press-detail">
                        <img src={pressImage} alt="" />
                      </Link>
                    </figure>
                    <div className="lower-content">
                      <div className="post-date">
                        <h4>
                          08<span>Jan</span>
                        </h4>
                      </div>
                      <h3>
                        <Link to="/press-detail">How to Manage Business’s Online Reputation</Link>
                      </h3>
                      <p>
                        Amet minim mollit duis sit enim aliqua dolor do amet officia. Pellentesque
                        etiam niss...
                      </p>
                      <ul className="post-info">
                        <li>
                          <i className="icon-32"></i>
                          <Link to="/press-detail">Admin</Link>
                        </li>
                        <li>
                          <i className="icon-33"></i>4 Comment
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pagination-wrapper centred pt_50">
                            <ul className="pagination">
                                <li><Link href="/blog"><i className="icon-40"></i></Link></li>
                                <li><Link href="/blog" className="current">1</Link></li>
                                <li><Link href="/blog">2</Link></li>
                                <li><Link href="/blog">3</Link></li>
                                <li><Link href="/blog"><i className="icon-41"></i></Link></li>
                            </ul>
                        </div> */}
          </div>
        </section>
      </div>
    </>
  );
}
