import { createRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import bannerApp from '../../assets/images/banner/banner-app1.png';
import shapeImage from '../../assets/images/shape/shape-2.png';
import shape from '../../assets/images/shape/shape-11.png';
import image from '../../assets/images/servicesDetails/rewards.png';
import aboutUsImage from '../../assets/images/aboutUs/about-02.png';
import ReCAPTCHA from 'react-google-recaptcha';

import CominSoonHeader from './subComponent/comingSoonHeader';
import CominSoonFooter from './subComponent/comingSoonFooter';
import { useDispatch } from 'react-redux';
import { subscribeUs } from 'store/slice/contact';
import { SILVERBACK_API } from 'services/apiConstant';
import { isObjectEmpty, validateEmpty, validEmail } from 'utils/appConstant';
import SVInput from 'component/SVInput';
import SVButton from 'component/SVButton';

export default function ComingSoon() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const recaptchaRef = createRef();
  const dispatch = useDispatch();
  const [notifyMeForm, setNotifyMeForm] = useState({});
  const [formError, setFormError] = useState({});
  const [captchaToken, setCaptchaToken] = useState('');

  const onCaptchaChange = (value) => {
    if (value) {
      setCaptchaToken(value);
      fetch(`${process.env.REACT_APP_API_URL}${SILVERBACK_API.verifyCaptcha}`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Data', data);
          setCaptchaToken(value);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleSubscribe = () => {
    if (isObjectEmpty(notifyMeForm)) {
      setFormError((formError) => ({
        ...formError,
        firstName: 'Please enter first name',
        lastName: 'Please enter last name',
        email: 'Email field is required.'
      }));
      return;
    }
    if (validateEmpty(notifyMeForm?.firstName)) {
      setFormError((formError) => ({
        ...formError,
        firstName: 'Please enter first name'
      }));
      return;
    }
    if (validateEmpty(notifyMeForm?.lastName)) {
      setFormError((formError) => ({
        ...formError,
        lastName: 'Please enter last name'
      }));
      return;
    }
    if (validateEmpty(notifyMeForm.email) || !validEmail(notifyMeForm.email)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter valid email.'
      }));
      return;
    }
    if (!captchaToken) {
      setFormError((formError) => ({
        ...formError,
        captchaTokenErr: 'Please verify you are human.'
      }));
      return;
    }
    let request = {
      email: notifyMeForm?.email,
      firstName: notifyMeForm?.firstName,
      lastName: notifyMeForm?.lastName
    };
    dispatch(subscribeUs(request, setNotifyMeForm));
  };

  return (
    <div className={`boxed_wrapper ltr`} style={{ background: 'black' }}>
      <CominSoonHeader />
      {/* {banner} */}
      <section className="banner-section p_relative pb-5">
        <Swiper className="banner-carousel">
          <SwiperSlide className="slide-item p_relative">
            <div className="bg-layer" style={{ backgroundImage: `url(${bannerApp})` }}></div>
            <div className="pattern-layer">
              <div className="pattern-2" style={{ backgroundImage: `url(${shapeImage})` }}></div>
            </div>
            <div className="auto-container">
              <div className="content-box">
                <h2>
                  Welcome to our&nbsp;<span>vibrant community!</span>
                </h2>
                <h5>Subscribe to our waitlist to be notified when we lauch.</h5>
                <p style={{ fontSize: '1rem' }}>
                  {` While we're putting the final touches on our website, we're thrilled to announce a
                  special treat exclusively for our subscribers!`}
                </p>
                <div className="btn-box">
                  <a
                    href="#notify-me"
                    className="theme-btn btn-one"
                    style={{
                      height: 45,
                      padding: 10,
                      borderRadius: 30,
                      paddingLeft: 25,
                      paddingRight: 25
                    }}>
                    Join our waiting list!
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      {/* {cashback} */}
      <section className="about-style-two">
        <div
          className="wow fadeInRightBig animated"
          data-wow-delay="00ms"
          data-wow-duration="2500ms">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content_block_three">
                  <div className="content-box mr_110">
                    <div className="sec-title mb_20">
                      <h6>CASHBACK REWARD</h6>
                      <h2>
                        Enjoy <span>1% CashBack </span>
                        on Every Credit Purchase!
                      </h2>
                    </div>
                    <div className="text-box mb_40">
                      <p>
                        {` That's right, every transaction with our Debit Card earns you 1% cashback.
                        Indulge in your favorite meals or manage your daily expenses — it's all
                        rewarding. The best part? There's zero annual fee. So, keep swiping all
                        month long and watch your savings grow!`}
                      </p>
                      <ul className="list-style-one clearfix">
                        <li>Every swipe on SilverBack Debit card earns you cashback.</li>
                        <li>
                          Simply use your card and watch your cashback accumulate. No need to track
                          points or categories.
                        </li>
                        <li>Redeem your cashback. </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image_block_two">
                  <div className="image-box">
                    <div className="image-shape">
                      <div
                        className="shape-3"
                        style={{
                          backgroundImage: `url(${shape})`,
                          borderRadius: 24 // Applies border radius to all corners
                        }}></div>
                      <div
                        className="shape-4"
                        style={{
                          backgroundImage: `url(${shape})`,
                          borderRadius: 24 // Applies border radius to all corners
                        }}></div>
                    </div>
                    <figure className="grow image">
                      <img
                        src={image}
                        style={{
                          borderRadius: 24 // Applies border radius to all corners
                        }}
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {Valued subscriber} */}
      <section className="about-style-two pt_120 ">
        <div
          className="wow fadeInRightBig animated"
          data-wow-delay="00ms"
          data-wow-duration="1500ms">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content_block_three">
                  <div className="content-box mr_110">
                    <div className="sec-title mb_20">
                      <h6>As a valued subscriber,</h6>
                      <h2>What other benefits can I expect?</h2>
                    </div>
                    <div className="text-box mb_40">
                      <p>
                        As a valued subscriber, you can look forward to a suite of benefits designed
                        to enhance your experience:
                      </p>
                      <ul className="list-style-one clearfix">
                        <li>
                          <b>Exclusive Offers</b>: Gain access to special promotions and discounts
                          curated just for our members.
                        </li>
                        <li>
                          <b>Priority Support</b>: Enjoy dedicated customer service with faster
                          response times.
                        </li>{' '}
                        <li>
                          <b>Enhanced Security</b>: Rest assured with advanced security features
                          protecting every transaction.
                        </li>{' '}
                      </ul>
                    </div>
                    <div className="text-box mb_40">
                      <p>
                        Join our community and unlock a treasure trove of perks, including a unique
                        feature to help you gauge your financial standing:
                      </p>
                      <ul className="list-style-one clearfix">
                        <li>
                          <b>Personalized Net Worth Analysis</b>: Collaborate with our financial
                          specialists to assess your net worth, giving you a clearer picture of your
                          financial health and helping you make informed decisions for the future.
                        </li>
                        <li>
                          {`  With this added advantage, along with our other subscriber benefits,
                          you're not just earning rewards — you're enhancing your financial savvy.
                          Let's prosper together!`}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image_block_two">
                  <div className="image-box">
                    <div className="image-shape">
                      <div
                        className="shape-3"
                        style={{
                          backgroundImage: `url(${shape})`
                        }}></div>
                      <div
                        className="shape-4"
                        style={{
                          backgroundImage: `url(${shape})`
                        }}></div>
                    </div>
                    <figure className="grow image">
                      <img
                        src={aboutUsImage}
                        alt=""
                        style={{
                          borderRadius: 24 // Applies border radius to all corners
                        }}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {Contact} */}
      <section id="notify-me" className="contact-section  pt_120 pb_60">
        <div className="container">
          <div className="sec-title centred mb_70">
            <h6>Join Our Waiting List</h6>
            <h5 style={{ paddingLeft: '15%', paddingRight: '15%' }}>
              Get ahead of the crowd! Sign up today and be first to access exclusive perks and
              updates.
            </h5>
          </div>
          <div className="form-inner">
            <div className="row clearfix">
              <div className="col-6 form-group">
                <SVInput
                  type="text"
                  name="username"
                  placeholder="First Name"
                  required
                  value={notifyMeForm?.firstName || ''}
                  onChange={(e) => {
                    setNotifyMeForm((notifyMeForm) => ({
                      ...notifyMeForm,
                      firstName: e.target.value
                    }));

                    setFormError((formError) => ({
                      ...formError,
                      firstName: ''
                    }));
                  }}
                  formError={formError?.firstName}
                />
              </div>
              <div className="col-6 form-group">
                <SVInput
                  type="text"
                  name="username"
                  placeholder="Last Name"
                  required
                  value={notifyMeForm?.lastName || ''}
                  onChange={(e) => {
                    setNotifyMeForm((notifyMeForm) => ({
                      ...notifyMeForm,
                      lastName: e.target.value
                    }));

                    setFormError((formError) => ({
                      ...formError,
                      lastName: ''
                    }));
                  }}
                  formError={formError?.lastName}
                />
              </div>
              <div className="col-12 form-group">
                <SVInput
                  type="email"
                  name="email"
                  placeholder="Your email"
                  required
                  value={notifyMeForm?.email || ''}
                  onChange={(e) => {
                    setNotifyMeForm((notifyMeForm) => ({
                      ...notifyMeForm,
                      email: e.target.value
                    }));
                    setFormError((formError) => ({
                      ...formError,
                      email: ''
                    }));
                  }}
                  formError={formError?.email}
                />
              </div>

              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                onChange={onCaptchaChange}
                theme={'dark'}
              />
              <span style={{ color: 'red', fontSize: 13 }}>{formError.captchaTokenErr}</span>
              <br />
              <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                <div className="d-flex justify-content-center">
                  <SVButton title="Submit" onClick={handleSubscribe} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* {Footer} */}
      <CominSoonFooter />
    </div>
  );
}
