import { useResponsive } from 'component/useResponsive';
import shapeImage from '../../assets/images/shape/shape-18.png';

export default function TopHeader({ backgroundImage, title }) {
  const { screenType } = useResponsive();

  return (
    <>
      <section className="page-title ">
        <div
          className="bg-layer"
          style={{
            backgroundImage: `url("${backgroundImage}")`
          }}></div>
        {screenType != 'mobile' && (
          <div className="pattern-layer">
            <div className="pattern-1" style={{ backgroundImage: `url(${shapeImage})` }}></div>
          </div>
        )}
        <div className="auto-container">
          <div className="content-box">
            <h1
              style={{
                textAlign: screenType === 'mobile' ? 'center' : ''
              }}>
              {title}
            </h1>
            {/* <ul className="bread-crumb clearfix">
              <li>
                <Link href="/">Home</Link>
              </li>
              <li>{breadcrumbTitle}</li>
            </ul> */}
          </div>
        </div>
      </section>
    </>
  );
}
