import { Outlet } from 'react-router-dom';
import SideItem from './sideItem';
import { useResponsive } from 'component/useResponsive';
import { color } from 'assets/css/color/color';

export default function DashBoradLayout() {
  const { screenType } = useResponsive();
  return (
    <div
      className={`boxed_wrapper ltr`}
      style={{ background: color.background, minHeight: '100vh' }}>
      <div className="d-flex">
        {screenType === 'desktop' && (
          <div className="sidebar">
            <SideItem />
          </div>
        )}
        <div
          className={`${screenType === 'desktop' ? '' : 'col-12'} `}
          style={{ marginLeft: screenType === 'desktop' ? 300 : '' }}>
          <div className={screenType === 'desktop' ? 'px-5 pt-3' : 'pt-3'}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
