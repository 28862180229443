export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};
export const validateEmpty = (str) => {
  return !str || str.length === 0;
};
export const isValidNumber = (str) => {
  const pattern = /^[0-9\b]+$/;
  return pattern.test(str);
};

export const validEmail = (val) => {
  const pattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  return pattern.test(val);
};

export const validPassword = () => {
  let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/;
  return regex;
};
