'use client';
import { Outlet } from 'react-router-dom';
import DataBg from '../DataBg';
import Logo from '../../assets/images/Logo1.png';
import Banner from '../../assets/images/banner/banner-wealth.png';
import Banner2 from '../../assets/images/banner/banner-transfer-mobile.png';
import Banner3 from '../../assets/images/banner/banner-app-mobile.png';
import Banner4 from '../../assets/images/banner/banner-net-worth.png';
import './index.css';
import { useResponsive } from 'component/useResponsive';
export default function AuthLayout() {
  const { screenType } = useResponsive();

  return (
    <>
      <DataBg />
      <div
        id="#top"
        style={{
          background: '#0e0e0e',
          minHeight: '100vh',
          maxHeight: 'auto',
          position: 'relative',
          width: '100%'
        }}
        className={`boxed_wrapper ltr `}>
        <div className="row">
          {screenType === 'desktop' && (
            <div
              className="col-lg-6"
              style={{
                height: '100vh'
              }}>
              <div
                id="carouselExampleCaptions"
                className="carousel slide "
                style={{
                  width: '100%',
                  position: 'relative',
                  overflow: 'hidden',
                  height: 650,
                  marginLeft: 18
                }}>
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={Banner3} className="d-block w-100" alt="..." />
                    <div
                      className="carousel-caption d-none d-md-block"
                      style={{
                        position: 'unset',
                        right: '15%',
                        left: '15%',
                        paddingTop: '4rem',
                        paddingBottom: '1.25rem',
                        color: '#fff',
                        textAlign: 'center'
                      }}>
                      <h5>
                        Download the SilverBack{' '}
                        <span style={{ color: '#0887FC' }}>Mobile banking App</span>
                      </h5>
                      <p>An app for convinient and secure access to all over financial services.</p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Banner4} className="d-block w-100" alt="..." />
                    <div
                      className="carousel-caption d-none d-md-block"
                      style={{
                        position: 'unset',
                        right: '15%',
                        left: '15%',
                        paddingTop: '4rem',
                        paddingBottom: '1.25rem',
                        color: '#fff',
                        textAlign: 'center'
                      }}>
                      <h5>Unlock personalized</h5>
                      <h5 style={{ color: '#0887FC' }}>wealth management </h5>

                      <p>
                        {`Unlock personalized wealth management solutions tailored your financial goals with Silverback.`}
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item ">
                    <img
                      src={Banner}
                      className="d-block w-100 img"
                      style={{ height: '100%' }}
                      alt="..."
                    />
                    <div
                      className="carousel-caption d-none d-md-block"
                      style={{
                        position: 'unset',
                        right: '15%',
                        left: '15%',
                        paddingTop: '4rem',
                        paddingBottom: '1.25rem',
                        color: '#fff',
                        textAlign: 'center'
                      }}>
                      <h5>Manage and grow your</h5>
                      <h5 style={{ color: '#0887FC' }}>net worth</h5>

                      <p>
                        {`Manage your net worth effortlessly whith silverback's comprehensive
                        financial tools.`}
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src={Banner2} className="d-block w-100" alt="..." />
                    <div
                      className="carousel-caption d-none d-md-block"
                      style={{
                        position: 'unset',
                        right: '15%',
                        left: '15%',
                        paddingTop: '4rem',
                        paddingBottom: '1.25rem',
                        color: '#fff',
                        textAlign: 'center'
                      }}>
                      <h5>Easily transfer money</h5>
                      <h5 style={{ color: '#0887FC' }}>securely and swiftly </h5>

                      <p>
                        {`Transfer your money securely and swiftly with Silverback's seemless money
                        transfer services.`}
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          )}
          <div className="col-lg-6 col-md-12 col-sm-12 py-3 bg-black  ">
            <div className="content-box">
              <div className={`col-${screenType === 'desktop' ? 9 : 11} mx-auto mt-3 `}>
                {/* <div className="sec-title mb_20 text-center">
                  <h6>Welcome To SilverBack</h6>
                  <h3> Internet Banking</h3>
                </div> */}
                <div style={{ textAlign: 'center', marginTop: 40 }}>
                  <img src={Logo} width={'45%'} />
                </div>

                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
