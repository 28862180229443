import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// import { AxiosInterceptor } from './services';
import { Toaster } from 'react-hot-toast';
import Router from './routes';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
function App() {
  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>

      </HelmetProvider>
      <Toaster
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
