import React, { useEffect } from 'react';

export default function PrivacyAndSecurity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const privacySecurityData = [
    {
      title: 'Introduction:',
      detail:
        'This Silverback Corp Digital Privacy Policy applies to your online interaction with Silverback Corp or any of its subsidiaries. This includes interactions on our owned websites, mobile applications, third-party advertisements, and pages. We may collect, use, and disclose personal information about you in accordance with this Policy.'
    },
    {
      title: 'Additional Policies:',
      detail:
        'Additional privacy policies and notices, including the Wells Fargo California Consumer Privacy Act Notice, are available on our Privacy, Cookies, Security, and Legal page. These policies address various privacy aspects, such as the rights of California residents under the CCPA and international privacy policies.'
    },
    {
      title: 'Consent:',
      detail:
        'By using our Sites or interacting with our advertisements or pages on third-party sites, you consent to this Policy and our use and disclosure of your information as described herein.'
    },
    {
      title: 'Information Collection:',
      detail:
        'We collect various types of information about you through online interactions, including inputted information, transaction data, location information, and device-related data. We also use technologies like cookies to collect information about your online activities.'
    },
    {
      title: 'Cookies:',
      detail:
        'Cookies are used to enhance your online experience, personalize content, and enable interest-based advertising. You can manage your cookie preferences through browser settings, but disabling cookies may limit site functionality.'
    },
    {
      title: 'Additional Technologies:',
      detail:
        'We may use technologies like pixel tags and web beacons for analytics and monitoring purposes. These technologies help us measure the effectiveness of our communications and improve our products and services.'
    },
    {
      title: 'Information Use and Disclosure:',
      detail:
        'We use information for various purposes, including servicing, communication, product improvement, and marketing. We may disclose information to service providers and third parties as permitted by law.'
    },
    {
      title: "Children's Privacy:",
      detail:
        'Our Sites are not directed at children under 13, and we do not knowingly collect personal information from them.'
    },
    {
      title: 'Policy Updates:',
      detail:
        'We update this Policy periodically, and any updates become effective when posted on the Site. Your continued use of the Site indicates acceptance of the updated Policy.'
    }
  ];
  return (
    <>
      <section className="service-style-three pt_80 pb_90">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            {/* <h6>Privacy & Security</h6> */}
            <h2>Privacy & Security</h2>
          </div>
          {privacySecurityData?.map((data, index) => {
            return (
              <div className="sec-title mb_20 pt-3" key={index}>
                <h6>{data?.title}</h6>
                <div className="detail-text">{data?.detail}</div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
