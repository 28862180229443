const API_VERSION = {
  v1: 'api/v1'
};
export const SILVERBACK_API = {
  subscribeUs: `${API_VERSION.v1}/save-subscribers`,
  contantUs: `${API_VERSION.v1}/contact-support`,
  stateList: `${API_VERSION.v1}/states`,
  verifyCaptcha: `${API_VERSION.v1}/cms/user/verify-recaptcha`,
  auth: {
    login: `${API_VERSION.v1}/user/auth/login`,
    signUp: `${API_VERSION.v1}/user/auth/signup`,
    verifyOtpApi: `${API_VERSION.v1}/auth/verify-email-otp`,
    sendOtp: `${API_VERSION.v1}/auth/send-verification-email`,
    forgotPassWord: `${API_VERSION.v1}/auth/forgot-password`
  }
  
};

export const STATUS_CODES = {
  SUCCESS: 200,
  FAILURE: 400,
  NOT_AUTHORIZED: 401,
  SERVER_ERROR: 500
};
