import Disclaimer from 'pages/landingPage/disclaimer';
import React from 'react';
import ComingSoonHeader from './comingSoonHeader';
import CominSoonFooter from './comingSoonFooter';

export default function CominSoonDisclaimer() {
  return (
    <div className={`boxed_wrapper ltr`} style={{ background: 'black' }}>
      <ComingSoonHeader />
      <Disclaimer />
      <CominSoonFooter />
    </div>
  );
}
