import SVButton from 'component/SVButton';
import SVInput from 'component/SVInput';
import SVSelect from 'component/SVSelect';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { signUpUser } from 'store/slice/auth';
import { stateList } from 'store/slice/profile';
import { isObjectEmpty, validateEmpty, validEmail } from 'utils/appConstant';
import './index.css';

export default function SignUp() {
  const [signUpForm, setSignUpForm] = useState({});
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const signUpForm = usesignUpForm();
  const { isLoading, stateListData } = useSelector((state) => state.profile);
  console.log(isLoading, 'isLoading');

  useEffect(() => {
    dispatch(stateList());
  }, []);

  const signUpSubmit = () => {
    const digit = /(?=.*[0-9])/;
    const specialChar = /[ -/:-@[-`{-~]/;
    if (isObjectEmpty(signUpForm)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Email field is required.',
        password: 'Password field is required.',
        name: 'Name filed is required',
        middleName: 'Middle Name filed is required',
        lastName: 'Last Name filed is required',
        ssn: 'SSN filed is required',
        dob: 'DOB filed is requird',
        phone: 'Phone number is required',
        address: 'Address field is required',
        city: 'Coty field is required',
        postalCode: 'Post Code field is required',
        state: 'Plesae slect state'
      }));
      return;
    }

    if (validateEmpty(signUpForm.email) || !validEmail(signUpForm.email)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter valid email.'
      }));
      return;
    }

    if (validateEmpty(signUpForm.password)) {
      setFormError((formError) => ({
        ...formError,
        password: 'Please enter password'
      }));
      return;
    }
    if (signUpForm.password.search(/[a-z]/) == -1) {
      setFormError((formError) => ({
        ...formError,
        password: 'Your password needs at least one lower case letter'
      }));
      return;
    }
    if (signUpForm.password.search(/[A-Z]/) == -1) {
      setFormError((formError) => ({
        ...formError,
        password: 'Your password needs at least one upper case letter'
      }));
      return;
    }
    if (signUpForm.password?.length < 8) {
      setFormError((formError) => ({
        ...formError,
        password: 'Password length must be at least 8 characters'
      }));
      return;
    }
    if (!digit.test(signUpForm.password) && !specialChar.test(signUpForm.password)) {
      setFormError((formError) => ({
        ...formError,
        password: 'Please enter one number or symbol'
      }));
      return;
    }

    // let formData = new FormData();
    // formData.append("username", signUpForm.email);
    // formData.append("password", signUpForm.password);

    var data = {
      familyName: signUpForm?.middleName,
      ssn: signUpForm?.ssn,
      dateOfBirth: signUpForm?.dob,
      postalCode: signUpForm?.postalCode,
      locality: signUpForm?.city,
      state: signUpForm?.state,
      loginName: '',
      deviceToken: '',
      deviceType: '',
      currentVersion: '',
      first_name: signUpForm?.name,
      surename: signUpForm?.lastName,
      email: signUpForm?.email,
      address: signUpForm?.address,
      phone: signUpForm?.phone,
      countryCode: '+1',
      countryFlag: '',
      referral_code: '',
      is_superuser: false,
      is_verified: false,
      password: signUpForm?.password
    };
    dispatch(signUpUser(data, navigate));
  };
  return (
    <div>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <h5>Sign Up To Your Account</h5>
      </div>
      <div className="mt-5">
        <SVInput
          text="text"
          name="email"
          icon="carbon:email"
          value={signUpForm?.email}
          placeholder="Email"
          onChange={(val) => {
            if (val) {
              setSignUpForm((signUpForm) => ({
                ...signUpForm,
                email: val.target.value
              }));
              if (val) {
                setFormError((formError) => ({
                  ...formError,
                  email: ''
                }));
              }
            }
          }}
          formError={formError?.email}
        />
      </div>
      <div className="row">
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="fluent:person-12-regular"
            type="text"
            name="name"
            placeholder="Name"
            value={signUpForm?.name}
            required
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  name: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    name: ''
                  }));
                }
              }
            }}
            formError={formError?.name}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="fluent:person-12-regular"
            type="text"
            name="middleName"
            placeholder="Middle Name"
            value={signUpForm?.middleName}
            required
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  middleName: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    middleName: ''
                  }));
                }
              }
            }}
            formError={formError?.middleName}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="fluent:person-12-regular"
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={signUpForm?.lastName}
            required
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  lastName: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    lastName: ''
                  }));
                }
              }
            }}
            formError={formError?.lastName}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="mdi:password"
            type={signUpForm?.showPass ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            value={signUpForm?.password}
            required
            showPassClick={() =>
              setSignUpForm((signUpForm) => ({
                ...signUpForm,
                showPass: !signUpForm?.showPass
              }))
            }
            endIcon={signUpForm?.showPass ? 'eva:eye-outline' : 'tabler:eye-off'}
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  password: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    password: ''
                  }));
                }
              }
            }}
            formError={formError?.password}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="material-symbols-light:id-card-outline"
            placeholder="SSN"
            name="ssn"
            type="text"
            value={signUpForm?.ssn}
            required
            maxLength={9}
            onChange={(e) => {
              const numeric = /^[0-9\b]+$/;
              if (e.target.value === '' || numeric.test(e.target.value)) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  ssn: e.target.value
                }));
                if (e) {
                  setFormError((formError) => ({
                    ...formError,
                    ssn: ''
                  }));
                }
              }
            }}
            formError={formError?.ssn}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="tabler:calendar"
            type="date"
            name="dob"
            placeholder="DOB"
            value={signUpForm?.dob}
            required
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  dob: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    dob: ''
                  }));
                }
              }
            }}
            formError={formError?.dob}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="material-symbols-light:id-card-outline"
            type="text"
            name="phone"
            placeholder="Phone Number"
            value={signUpForm?.phone}
            required
            maxLength={10}
            onChange={(e) => {
              const numeric = /^[0-9\b]+$/;
              if (e.target.value === '' || numeric.test(e.target.value)) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  phone: e.target.value
                }));
                if (e) {
                  setFormError((formError) => ({
                    ...formError,
                    phone: ''
                  }));
                }
              }
            }}
            formError={formError?.phone}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="carbon:location"
            type="text"
            name="address"
            placeholder="Address"
            value={signUpForm?.address}
            required
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  address: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    address: ''
                  }));
                }
              }
            }}
            formError={formError?.address}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="carbon:location"
            type="text"
            name="city"
            placeholder="City"
            value={signUpForm?.city}
            required
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  city: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    city: ''
                  }));
                }
              }
            }}
            formError={formError?.city}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVInput
            icon="carbon:location"
            type="text"
            name="postalCode"
            placeholder="Postal Code"
            value={signUpForm?.postalCode}
            maxLength={5}
            required
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  postalCode: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    postalCode: ''
                  }));
                }
              }
            }}
            formError={formError?.postalCode}
          />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-3 ">
          <SVSelect
            icon="carbon:location"
            type="text"
            name="postalCode"
            placeholder="Postal Code"
            value={signUpForm?.postalCode}
            options={stateListData}
            required
            onChange={(val) => {
              if (val) {
                setSignUpForm((signUpForm) => ({
                  ...signUpForm,
                  postalCode: val.target.value
                }));
                if (val) {
                  setFormError((formError) => ({
                    ...formError,
                    postalCode: ''
                  }));
                }
              }
            }}
            formError={formError?.postalCode}
          />
        </div>
      </div>

      <div className="mt-5">
        <SVButton title={'Sign Up'} onClick={signUpSubmit} />
      </div>
      <div>
        <div className="d-flex justify-content-center mt-2">
          <div className="textMuted">Have an account?</div> &nbsp;
          <Link to="/net-banking/login">
            <div className="link">Sign In</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
