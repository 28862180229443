import { color } from 'assets/css/color/color';
import AppBar from 'layout/dashBoardLayout/appBar';
import cashBackImage from '../../../assets/images/resource/cashback.png';
import sendMoneyIcon from '../../../assets/images/SB-Icons/send-money.png';
import SVButton from 'component/SVButton';
import profileImage from '../../../assets/images/SB-Icons/profile.png';
import { useResponsive } from 'component/useResponsive';
export default function DashBoard() {
  const { screenType } = useResponsive();
  const TranscationData = [
    {
      type: 'credit',
      amount: ' +$45',
      name: 'HDFC CARD'
    },
    {
      type: 'debit',
      amount: ' +$250',
      name: 'Birthday Gift'
    },
    {
      type: 'credit',
      amount: ' +$55',
      name: 'House Cleaning'
    },
    {
      type: 'credit',
      amount: ' +$45',
      name: 'HDFC CARD'
    }
  ];
  return (
    <>
      <AppBar
        title={
          <div className="textLinearBackground h1 fw-bolder" style={{ lineHeight: '33px' }}>
            <div>Hi Marcus,</div>
            <span className="h4 fw-bolder">Welcome Back!</span>
          </div>
        }
      />
      <div
        className={`bg-black py-3 ${screenType === 'desktop' ? 'px-5' : 'px-2 pt-5'}  mt-4 rounded-4`}>
        <div className="row">
          {/* total balance */}
          <div className={screenType === 'desktop' ? 'col-6' : 'col-12'}>
            <div className="px-4 py-3 rounded-3" style={{ background: color?.background }}>
              <h5 className="textLinearBackground">Total Balance</h5>
              <div
                className="textLinearBackground mt-2 fw-bold"
                style={{ fontSize: 40, letterSpacing: 0.8 }}>
                $25,000.76
              </div>
              <div className="d-flex gap-3 my-2 overflow-auto">
                <div className="account-total-count" style={{ minWidth: '196px' }}>
                  <div>
                    <h6 className="fw-bolder text-black">Spend Account</h6>
                    <h4 className="fw-bolder text-black mt-2">$370,000.00</h4>
                  </div>
                </div>
                <div className="account-total-count" style={{ minWidth: '196px' }}>
                  <div>
                    <h6 className="fw-bolder text-black">Saving Account</h6>
                    <h4 className="fw-bolder text-black mt-2">$370,000.76</h4>
                  </div>
                </div>
                <div className="account-total-count" style={{ minWidth: '196px' }}>
                  <div>
                    <h6 className="fw-bolder text-black">Splurge Account</h6>
                    <h4 className="fw-bolder text-black mt-2">$370,000.76</h4>
                  </div>
                </div>
              </div>
            </div>
            {/* cash-back image */}
            <div className="my-4">
              <img src={cashBackImage} alt="" />
            </div>

            {/* quick-actions */}
            <h5 className="textLinearBackground">Quick Actions</h5>
            <div className="my-3 p-3 d-flex gap-3 overflow-auto" style={{ background: color.grey }}>
              <div className="quick-action-box" style={{ minWidth: '140px' }}>
                <div>
                  <div
                    className="linearBackground d-flex justify-content-center align-items-center mx-auto"
                    style={{ height: '55px', width: '55px', borderRadius: 5 }}>
                    <img src={sendMoneyIcon} alt="..." height={'40%'} width={'40%'} />
                  </div>
                  <div className="fw-normal mt-3">Send Money</div>
                </div>
              </div>
              <div className="quick-action-box" style={{ minWidth: '140px' }}>
                <div>
                  <div
                    className="linearBackground d-flex justify-content-center align-items-center mx-auto"
                    style={{ height: '55px', width: '55px', borderRadius: 5 }}>
                    <img src={sendMoneyIcon} alt="..." height={'40%'} width={'40%'} />
                  </div>
                  <div className="fw-normal mt-3">Send Money</div>
                </div>
              </div>
              <div className="quick-action-box" style={{ minWidth: '140px' }}>
                <div>
                  <div
                    className="linearBackground d-flex justify-content-center align-items-center mx-auto"
                    style={{ height: '55px', width: '55px', borderRadius: 5 }}>
                    <img src={sendMoneyIcon} alt="..." height={'40%'} width={'40%'} />
                  </div>
                  <div className="fw-normal mt-3">Send Money</div>
                </div>
              </div>
              <div className="quick-action-box" style={{ minWidth: '140px' }}>
                <div>
                  <div
                    className="linearBackground d-flex justify-content-center align-items-center mx-auto"
                    style={{ height: '55px', width: '55px', borderRadius: 5 }}>
                    <img src={sendMoneyIcon} alt="..." height={'40%'} width={'40%'} />
                  </div>
                  <div className="fw-normal mt-3">Send Money</div>
                </div>
              </div>
            </div>
          </div>
          <div className={screenType === 'desktop' ? 'col-6' : 'col-12'}>
            <div className="row mt-3">
              <div className="col-6">
                <SVButton title="Account Statistics" />
              </div>
              <div className="col-6">
                <SVButton
                  background="transparent"
                  title="Silverback Rewards"
                  style={{ border: '0.5px solid #707070' }}
                />
              </div>
            </div>
            {/* account statics */}
            <div className="mt-4 p-4" style={{ border: '0.5px solid #707070', height: '279px' }}>
              <div className="row">
                <div className={screenType === 'desktop' ? 'col-5' : 'col'}>
                  <h5 className="textLinearBackground">Account Statistics </h5>
                </div>
                <div className={screenType === 'desktop' ? 'col-2' : 'col'}>
                  <div style={{ background: '#1098DB', textAlign: 'center', fontWeight: 600 }}>
                    YTD
                  </div>
                </div>
                <div className={screenType === 'desktop' ? 'col-2' : 'col'}>
                  <div style={{ background: '#1098DB', textAlign: 'center', fontWeight: 600 }}>
                    2014
                  </div>
                </div>
              </div>
            </div>
            {/* transcation history */}
            <div className="d-flex justify-content-between my-4">
              <h6 className="textLinearBackground">Transcation History </h6>
              <h6 className="textLinearBackground">Today</h6>
            </div>
            <div className="p-3" style={{ background: color.background }}>
              <div className="row gx-5">
                {TranscationData?.map((item, index) => {
                  return (
                    <>
                      <div key={index} className="col-1">
                        <div
                          className="linearBackground d-flex justify-content-center align-items-center mx-auto"
                          style={{ height: '55px', width: '55px', borderRadius: 50 }}>
                          <img src={profileImage} alt="..." height={'100%'} width={'100%'} />
                        </div>
                      </div>
                      <div className="col-9 ">
                        <div className="d-block pt-1" style={{ paddingLeft: '1.6rem' }}>
                          <div className="textLinearBackground fw-bolder" style={{ fontSize: 19 }}>
                            {item?.name}
                          </div>
                          <p className="textLinearBackground fw-bolder">13th April 2024 </p>
                        </div>
                      </div>
                      <div
                        className="col-2 fw-bold pt-2"
                        style={{
                          color: item?.type === 'credit' ? '#10DB39' : '#EC1C24',
                          fontSize: 17
                        }}>
                        {item?.amount}
                      </div>
                      {index != TranscationData?.length - 1 && (
                        <div
                          className={`col-12 my-3 ${screenType === 'desktop' ? 'px-4' : 'px-2'}`}>
                          <div style={{ border: '1px solid #707070' }}></div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
