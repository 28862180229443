import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

const authData = secureLocalStorage.getItem(process.env.REACT_APP_USER_AUTH);
const token = authData?.access_token;

// axios api
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  validateStatus: (status) => {
    if (status === 401) {
      secureLocalStorage.clear();
      window.location.href = '/login';
      return false;
    } else {
      return true;
    }
  },
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    Authorization: `Bearer ${token}`
  }
});

const apiFormData = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  validateStatus: (status) => {
    if (status === 401) {
      secureLocalStorage.clear();
      window.location.href = '/login';
      return false;
    } else {
      return true;
    }
  },
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    Authorization: `Bearer ${token}`
  }
});

const apiFormURLEncode = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  validateStatus: (status) => {
    if (status === 401) {
      secureLocalStorage.clear();
      window.location.href = '/login';
      return false;
    } else {
      return true;
    }
  },
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    Authorization: `Bearer ${token}`
  }
});

const uploadApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: false,
  validateStatus: (status) => {
    if (status === 401) {
      secureLocalStorage.clear();
      window.location.href = '/login';
      return false;
    } else {
      return true;
    }
  },
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    Authorization: `Bearer ${token}`
  }
});

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const reqInterceptor = (request) => {
      const authData = secureLocalStorage.getItem(process.env.REACT_APP_USER_AUTH);

      const token = authData?.access_token;
      if (token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    };

    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      if (error.response.status === 401) {
        secureLocalStorage.clear();
        navigate('/login');
      }
      return Promise.reject(error);
    };

    const requestInterceptor = api.interceptors.request.use(reqInterceptor, errInterceptor);
    const interceptor = api.interceptors.response.use(resInterceptor, errInterceptor);
    return () => {
      api.interceptors.response.eject(interceptor);
      api.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  return children;
};

export { api, apiFormURLEncode, uploadApi, apiFormData };
export { AxiosInterceptor };
