import toast from 'react-hot-toast';

export const successToast = (title, message, toastId) => {
  toast.custom(
    <div
      className="toast  bg-light bg-gradient
    text-white fade show">
      <div
        className="toast-header bg-success bg-gradient
text-white">
        <strong className="me-auto">
          <i className="bi-gift-fill"></i> {title}
        </strong>
        <button
          type="button"
          className="btn-close btn-close-white"
          data-bs-dismiss="toast"></button>
      </div>
      <div className="toast-body text-black">{message}</div>
    </div>,
    { id: toastId }
  );
};

export const errorToast = (title, message, toastId) => {
  toast.custom(
    <div className="toast bg-light text-white fade show">
      <div className="toast-header bg-danger text-white">
        <strong className="me-auto">
          <i className="bi-gift-fill"></i> {title}
        </strong>
        <button
          type="button"
          className="btn-close btn-close-white"
          data-bs-dismiss="toast"></button>
      </div>
      <div className="toast-body text-black">{message}</div>
    </div>,
    { id: toastId }
  );
};

export const customToast = (messsage, toastId, icon) => {
  toast(messsage, {
    id: toastId,
    position: 'top-right',
    // Styling
    style: {},
    className: '',
    // Custom Icon
    icon: icon,
    // Change colors of success/error/loading icon
    iconTheme: {
      primary: '#000',
      secondary: '#fff'
    }
  });
};
