import React, { useEffect } from 'react';

export default function Disclaimer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const disclaimerData = [
    {
      title: 'Accuracy of Information:',
      detail:
        'While we strive to provide accurate and up-to-date information, we cannot guarantee the completeness, accuracy, or timeliness of the content provided on our platforms. Information provided is for general informational purposes only and should not be construed as financial advice.'
    },
    {
      title: 'Availability of Services:',
      detail:
        ' We make reasonable efforts to ensure the availability and functionality of our digital platforms. However, we do not warrant that our services will be uninterrupted, error-free, or free from technical issues. We reserve the right to modify, suspend, or terminate services at any time without prior notice.'
    },
    {
      title: 'Third-Party Links:',
      detail:
        ' Our platforms may contain links to third-party websites or resources. Silverback Corp does not endorse or control these third-party sites and is not responsible for their content, accuracy, or availability. Use of third-party sites is at your own risk, and you should review their terms and privacy policies.'
    },
    {
      title: 'Security:',
      detail:
        'We implement industry-standard security measures to protect your personal and financial information. However, no system is entirely secure, and we cannot guarantee the absolute security of data transmitted over the internet. You are responsible for safeguarding your login credentials and taking precautions to protect your account.'
    },
    {
      title: 'Financial Products and Services: ',
      detail:
        'Silverback Corp offers various financial products and services, which are subject to terms and conditions. Terms of use, fees, interest rates, and eligibility criteria may apply to these products and services. We recommend reviewing product disclosures and agreements before using our services.'
    },
    {
      title: 'Regulatory Compliance:',
      detail:
        'Silverback Corp is regulated by relevant financial authorities and complies with applicable laws and regulations. Our services may not be available in all jurisdictions, and we reserve the right to restrict access based on legal requirements.'
    },
    {
      title: 'Disclaimer of Liability: ',
      detail:
        'In no event shall Silverback Corp, its directors, officers, employees, or affiliates be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of our services or reliance on information provided. This includes, but is not limited to, loss of profits, data, or goodwill.'
    }
  ];
  return (
    <>
      <section className="service-style-three pt_80 pb_90">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h2>Disclaimer</h2>
            <div className="detail-text pt-5" style={{ textAlign: 'left', marginBottom: '-2rem' }}>
              Silverback Corp operates as a neo bank providing financial services through digital
              platforms. By accessing or using our services, you acknowledge and agree to the
              following disclaimer:
            </div>
          </div>
          {disclaimerData?.map((data, index) => {
            return (
              <div className="sec-title mb_20 pt-3" key={index}>
                <h6>{data?.title}</h6>
                <div className="detail-text">{data?.detail}</div>
              </div>
            );
          })}
          <div className="detail-text pt-4">
            By accessing or using our services, you agree to indemnify and hold harmless Silverback
            Corp from any claims, losses, liabilities, or expenses arising out of your use of our
            platforms or violation of these terms.
          </div>
          <div className="detail-text pt-4">
            This disclaimer is subject to change without notice. Please review periodically for
            updates. If you do not agree with these terms, please refrain from using our services.
          </div>
        </div>
      </section>
    </>
  );
}
