import React, { useEffect } from 'react';
import TechSupportDetail from './techSupportDetail';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/contact-us-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/contact-us.png';
import TechSupportForm from './techSupportForm';
import { useResponsive } from 'component/useResponsive';

export default function TechSupport() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { screenType } = useResponsive();

  return (
    <>
      <TopHeader
        backgroundImage={screenType === 'mobile' ? mobileImage : desktopImage}
        title={'Contact US'}
      />
      <TechSupportDetail />
      <TechSupportForm />
      {/* <Subscribe /> */}
    </>
  );
}
