import React from 'react';
import logo from '../../assets/images/Logo1.png';
import { navConfig } from './config';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './index.css';
import secureLocalStorage from 'react-secure-storage';
export default function SideItem() {
  let location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    secureLocalStorage.clear();
    navigate('/');
  };
  return (
    <div>
      <div className="d-flex justify-content-center pt-5">
        <img src={logo} alt="" width={'70%'} />
      </div>
      <div className="mt-5 d-grid mb-5 ">
        {navConfig?.map((item, index) => {
          const active = location.pathname === item.path ? true : false;
          return (
            <Link key={index} to={item.path} style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={active ? 'linearBackground sideItem' : 'sideItem'}>
                <Icon icon={item?.icon} width={30} color={active ? 'black' : '#848482'} />
                <div
                  className=" link"
                  style={{
                    paddingLeft: '2rem',
                    paddingTop: '0.4rem',
                    color: active ? 'black' : '#848482'
                  }}>
                  {item?.title}
                </div>
              </div>
            </Link>
          );
        })}
        <div>
          <div className="sideItem" style={{ paddingLeft: '2rem', marginTop: '20%' }}>
            <Icon icon={'material-symbols:contact-support-outline'} width={30} color="#848482" />
            <div
              className=" link"
              style={{ paddingLeft: '2rem', paddingTop: '0.3rem', color: '#848482' }}>
              Support
            </div>
          </div>
          <div
            className="sideItem"
            style={{ paddingLeft: '2rem', paddingTop: '0.8rem', color: '#848482' }}>
            <Icon icon={'ant-design:setting-outlined'} width={30} color="#848482" />
            <div
              className=" link"
              style={{ paddingLeft: '2rem', paddingTop: '0.4rem', color: '#848482' }}>
              Setting
            </div>
          </div>
          <div className="sideItem" style={{ paddingLeft: '2rem' }} onClick={handleLogout}>
            <Icon icon={'heroicons-outline:logout'} width={30} color="red" />
            <div
              className=" link"
              style={{ paddingLeft: '2rem', paddingTop: '0.4rem', color: 'red' }}>
              Logout
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
