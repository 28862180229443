import React from 'react';
import image from 'assets/images/servicesDetails/networth.png';
import shape from 'assets/images/shape/shape-11.png';

export default function AboutNetWorth() {
  return (
    <section className="about-style-two pt_120 pb_120">
      <div className="wow fadeInUpBig animated" data-wow-delay="00ms" data-wow-duration="1500ms">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_three">
                <div className="content-box mr_110">
                  <div className="sec-title mb_20">
                    <h6>About Net Worth</h6>
                    <h2>
                      Manage and grow your net worth effortlessly with
                      <span>{"Silverback's"} </span>
                      comprehensive financial tools.
                    </h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      We offer specialized net worth services designed to assist clients in
                      assessing their assets and enhancing their financial standing through
                      strategic decision-making and intelligent investments. Our tailored solutions
                      are aimed at optimizing net worth by implementing well-informed financial
                      strategies, ensuring our clients achieve sustained growth and prosperity.
                    </p>
                    <ul className="list-style-one clearfix">
                      <li>We will help you understand your assets</li>
                      <li>Plan for short- and long-term success</li>
                      <li>Reduce liabilities </li>
                      <li>We will provide you with tools to increase your net worth</li>
                      <li>Your liabilities</li>
                    </ul>
                  </div>
                  {/* <div className="btn-box">
                  <Link href="/about" className="theme-btn btn-one">
                    Discover More
                  </Link>
                </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_two">
                <div className="image-box">
                  <div className="image-shape">
                    <div
                      className="shape-3"
                      style={{
                        backgroundImage: `url(${shape})`
                      }}></div>
                    <div
                      className="shape-4"
                      style={{
                        backgroundImage: `url(${shape})`
                      }}></div>
                  </div>
                  <figure className="grow image">
                    <img src={image} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
