import React from 'react';
import aboutImage from 'assets/images/aboutUs/about-02.png';
import shape from 'assets/images/shape/shape-11.png';

export default function AboutUsContent() {
  return (
    <section className="about-style-two pt_120 pb_120">
      <div className="wow fadeInUpBig animated" data-wow-delay="00ms" data-wow-duration="1500ms">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_three">
                <div className="content-box mr_110">
                  <div className="sec-title mb_20">
                    <h6>About SilverBack</h6>
                    <h2>
                      Silverback operates entirely online, offering innovative financial services
                      without physical branches.
                    </h2>
                  </div>
                  <div className="text-box mb_40">
                    <p>
                      {` At Silverback, we're not just a neobank; we're your partner in financial
                      success. With a strong focus on wealth management and net worth services, we
                      empower our clients through financial education honed over 20 years in the
                      Fintech sector. Join us on the journey towards financial empowerment and
                      prosperity`}
                    </p>
                    <ul className="list-style-one clearfix">
                      <li>We will help you understand your assets</li>
                      <li>Plan for short- and long-term success</li>
                      <li>Reduce liabilities </li>
                      <li>We will provide you with tools to increase your net worth</li>
                      <li>Your liabilities</li>
                    </ul>
                  </div>
                  {/* <div className="btn-box">
                  <Link href="/about" className="theme-btn btn-one">
                    Discover More
                  </Link>
                </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_two">
                <div className="image-box">
                  <div className="image-shape">
                    <div
                      className="shape-3"
                      style={{
                        backgroundImage: `url(${shape})`
                      }}></div>
                    <div
                      className="shape-4"
                      style={{
                        backgroundImage: `url(${shape})`
                      }}></div>
                  </div>
                  <figure className="grow image">
                    <img src={aboutImage} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
