import React from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/rewards-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/Rewards.png';
import contentBgImage from 'assets/images/servicesDetails/rewards.png';
import ServiceDetails from './serviceDetails';
import { useEffect } from 'react';
import { useResponsive } from 'component/useResponsive';
export default function Rewards() {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const masterCardFeatureData = {
    title: 'Rewards',
    backgroundImage: screenType === 'mobile' ? mobileImage : desktopImage,
    contentBgImage: contentBgImage,
    content: [
      {
        title: 'Cashback Rewards',
        desc: 'Earn cashback on eligible purchases with banking rewards, adding value to everyday spending.'
      },
      {
        title: 'Security and Reliability',
        desc: ' Accumulate bonus points for various transactions, redeemable for perks, discounts, or merchandise.'
      },
      {
        title: 'Automatic Budgeting and Savings',
        desc: 'Enjoy interest rate benefits on savings or other accounts as a banking reward, enhancing overall financial returns.'
      }
    ]
  };

  return (
    <>
      <TopHeader
        backgroundImage={masterCardFeatureData?.backgroundImage}
        title={masterCardFeatureData?.title}
      />
      <ServiceDetails servicesData={masterCardFeatureData} />
      {/* <Subscribe /> */}
    </>
  );
}
