import { Navigate, useRoutes } from 'react-router-dom';
import LandingPage from 'pages/landingPage';
import LandinPageLayout from 'layout/landingPageLayout';
import AuthLayout from 'layout/authLayout/index';
import WealthManagment from 'pages/landingPage/sections/services/wealthManagment';
import NetWorth from 'pages/landingPage/sections/services/netWorth';
import Login from 'pages/customerPortal/auth/login/index';
import TechSupport from 'pages/landingPage/contact';
import MasterCardFeature from 'pages/landingPage/sections/services/subComponent/masterCardFeature';
import DirectDepositFeature from 'pages/landingPage/sections/services/subComponent/directDepositFeature';
import NetWorthService from 'pages/landingPage/sections/services/subComponent/netWorthService';
import WealthManagmentService from 'pages/landingPage/sections/services/subComponent/wealthManagmentService';
import Rewards from 'pages/landingPage/sections/services/subComponent/rewards';
import MoneyTransferService from 'pages/landingPage/sections/services/subComponent/moneyTransferService';
import AboutUS from 'pages/landingPage/sections/aboutUs';
import RewardsPage from 'pages/landingPage/disclaimer/rewards';
import FAQPage from 'pages/landingPage/faq';
import MediaPage from 'pages/landingPage/pressRelease';
import PressDetail from 'pages/landingPage/pressRelease/section/pressDetail';
import SignUp from 'pages/customerPortal/auth/signUp';
import ForgotPassWord from 'pages/customerPortal/auth/forgot';
import secureLocalStorage from 'react-secure-storage';
import DashBoradLayout from 'layout/dashBoardLayout';
import DashBoard from 'pages/customerPortal/dashBoard';
import Otp from 'pages/customerPortal/auth/otp/otp';
import ComingSoon from 'pages/comingSoon';
import ComingSoonTerms from 'pages/comingSoon/subComponent/comingSoonTerms';
import ComingSoonPrivacy from 'pages/comingSoon/subComponent/comingSoonPrivacy';
import CominSoonDisclaimer from 'pages/comingSoon/subComponent/cominSoonDisclaimer';
import PrivacyAndSecurity from 'pages/landingPage/privacyAndSecurity';
import TermsAndCondition from 'pages/landingPage/termsAndCondition';
import PrivacyPolicies from 'pages/landingPage/disclaimer/privacyPolicy';
import Disclaimer from 'pages/landingPage/disclaimer';

export default function Router() {
  const authData = secureLocalStorage.getItem(process.env.REACT_APP_USER_AUTH);

  const routes = useRoutes([
    {
      path: '/',
      // element: !authData ? <LandinPageLayout /> : <Navigate to="/user/dashboard" />,
      element: process.env.REACT_APP_MODE == 'production' ? <ComingSoon /> : !authData ? <LandinPageLayout /> : <Navigate to="/user/dashboard" />,
      children: [
        { path: '/', element: <LandingPage /> },
        { path: '/wealth-insights', element: <WealthManagment /> },
        // { path: "/ourServices", element: <OurServices /> },
        {
          path: '/services/mastercard-feature',
          element: <MasterCardFeature />
        },
        {
          path: '/services/direct-deposit-feature',
          element: <DirectDepositFeature />
        },
        {
          path: '/services/net-worth-service',
          element: <NetWorthService />
        },
        {
          path: '/services/wealth-managment-service',
          element: <WealthManagmentService />
        },
        {
          path: '/services/rewards',
          element: <Rewards />
        },
        {
          path: '/services/money-transfer-service',
          element: <MoneyTransferService />
        },
        { path: '/net-worth', element: <NetWorth /> },
        { path: '/contact', element: <TechSupport /> },

        { path: '/about-us', element: <AboutUS /> },
        { path: '/rewards', element: <RewardsPage /> },
        { path: '/faq', element: <FAQPage /> },
        { path: '/press-releases', element: <MediaPage /> },
        { path: '/press-detail', element: <PressDetail /> }, 
        { path: '/privacy-security', element: <PrivacyAndSecurity /> },
        { path: '/terms-condition', element: <TermsAndCondition /> },
        { path: '/privacy-policy', element: <PrivacyPolicies /> },
        { path: '/disclaimer', element: <Disclaimer /> }
      ]
    },
    {
      path: '/net-banking',
      element: authData ? <Navigate to={'/'} /> : <AuthLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'sign-up', element: <SignUp /> },
        { path: 'forgot-password', element: <ForgotPassWord /> },
        { path: 'otp', element: <Otp /> }
      ]
    },
    {
      path: '/user',
      element: <DashBoradLayout />,
      children: [{ path: 'dashboard', element: <DashBoard /> }]
    },
    {
      path: '/silver-back',
      element: <ComingSoon />
    },
    { path: '/silverback/terms-condition', element: <ComingSoonTerms /> },
    { path: '/silverback/privacy-policy', element: <ComingSoonPrivacy /> },
    { path: '/silverback/disclaimer', element: <CominSoonDisclaimer /> }
    // { path: '*', element: <NotFoundPage /> }
  ]);

  return routes;
}
