import React from 'react';
import { Icon } from '@iconify/react';

export default function SVSelect(props) {
  const { formError, style, icon, endIcon, options, showPassClick } = props;

  return (
    <>
      <div className="input-group">
        {icon && (
          <span className="input-group-text" id="basic-addon2">
            <Icon icon={icon} height={23} color="white" />
          </span>
        )}
        <select
          className="form-select"
          aria-label="Default select example"
          style={{
            ...style,
            border: '1px solid #565656',
            borderStyle: endIcon ? 'solid none solid none' : icon ? 'solid solid solid none' : '',
            color: 'white',
            borderRadius: '0 5 5 5 ',
            padding: 15,
            fontSize: 15,
            fontWeight: 600,
            letterSpacing: 0.6,
            backgroundColor: 'transparent',
            outline: 'none !important'
          }}>
          <option selected>Select state</option>
          {options?.map((data, index) => {
            return (
              <option value={data?.id} key={index}>
                {data?.description}
              </option>
            );
          })}
        </select>
        {endIcon && (
          <span onClick={showPassClick} className="input-icon-text" id="basic-addon2">
            <Icon icon={endIcon} height={23} color="white" />
          </span>
        )}
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 form-group">
        <span
          style={{
            color: 'red',
            fontSize: 13,
            fontWeight: 600,
            letterSpacing: 0.5
          }}>
          {formError}
        </span>
      </div>
    </>
  );
}
