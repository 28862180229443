import { useState } from 'react';
import { Link } from 'react-router-dom';

const MobileMenu = ({ isSidebar, handleMobileMenu, handleSidebar }) => {
  const [isActive, setIsActive] = useState({
    status: false,
    key: '',
    subMenuKey: ''
  });

  const handleToggle = (key, subMenuKey = '') => {
    if (isActive.key === key && isActive.subMenuKey === subMenuKey) {
      setIsActive({
        status: false,
        key: '',
        subMenuKey: ''
      });
    } else {
      setIsActive({
        status: true,
        key,
        subMenuKey
      });
    }
  };
  return (
    <>
      <div className="mobile-menu">
        <div className="menu-backdrop" onClick={handleMobileMenu} />
        <div className="close-btn" onClick={handleMobileMenu}>
          <i className="fas fa-times"></i>
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="" />
            </Link>
          </div>

          {/*menu-outer*/}
          <div className="menu-outer">
            <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
              <ul className="navigation clearfix">
                <li onClick={handleMobileMenu}>
                  <Link to="/login">Login</Link>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/about-us">About</Link>
                </li>
                <li className={isActive.key === 2 ? 'dropdown current' : 'dropdown'}>
                  <Link href="/#">Bussiness</Link>
                  <ul
                    style={{
                      display: `${isActive.key === 2 ? 'block' : 'none'}`
                    }}>
                    <li onClick={handleMobileMenu}>
                      <Link to="/wealth-insights">Wealth Managment Services</Link>
                    </li>
                    <li onClick={handleMobileMenu}>
                      <Link to="/net-worth">Net Worth</Link>
                    </li>
                  </ul>
                  <li onClick={handleMobileMenu}>
                    <Link to="/rewards">Rewards</Link>
                  </li>
                  <div
                    className={isActive.key === 2 ? 'dropdown-btn open' : 'dropdown-btn'}
                    onClick={() => handleToggle(2)}>
                    <span className="fa fa-angle-right" />
                  </div>
                </li>
                <li onClick={handleMobileMenu}>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </div>
          {/*menu-outer end*/}
          <div className="contact-info">
            <h4>Contact Info</h4>
            <ul>
              <li>Chicago 12, Melborne City, USA</li>
              <li>
                <Link href="tel:+8801682648101">+88 01682648101</Link>
              </li>
              <li>
                <Link href="mailto:info@example.com">info@example.com</Link>
              </li>
            </ul>
          </div>

          {/*Social Links*/}
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <Link href="/">
                  <span className="fab fa-twitter"></span>
                </Link>
              </li>
              <li>
                <Link href="/">
                  <span className="fab fa-facebook-square"></span>
                </Link>
              </li>
              <li>
                <Link href="/">
                  <span className="fab fa-pinterest-p"></span>
                </Link>
              </li>
              <li>
                <Link href="/">
                  <span className="fab fa-instagram"></span>
                </Link>
              </li>
              <li>
                <Link href="/">
                  <span className="fab fa-youtube"></span>
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* End Mobile Menu */}
      <div
        className="nav-overlay"
        style={{ display: `${isSidebar ? 'block' : 'none'}` }}
        onClick={handleSidebar}
      />
    </>
  );
};
export default MobileMenu;
