/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../services';
import { SILVERBACK_API } from 'services/apiConstant';

const slice = createSlice({
  name: 'profile',
  initialState: {
    stateListData: null
  },
  reducers: {
    loadingSuccess: (state, action) => {
      state.isLoading = action.payload;
    },
    //For listing

    stateListSuccess: (state, action) => {
      state.stateListData = action.payload.data;
    },
    stateListFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    }
  }
});

// ** Actions **
const { stateListSuccess, stateListFailed, loadingSuccess } = slice.actions;

export const stateList = () => async (dispatch) => {
  try {
    await api.post(SILVERBACK_API.stateList).then((response) => {
      if (response) {
        dispatch(stateListSuccess(response.data));
      } else {
        dispatch(stateListFailed(response.error));
      }
      dispatch(loadingSuccess(false));
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export default slice.reducer;
