import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import headerlogo from '../../../assets/images/logo.png';
import { useResponsive } from 'component/useResponsive';

export default function ComingSoonHeader() {
  const [scroll, setScroll] = useState(0);
  const { screenType } = useResponsive();
  useEffect(() => {
    const WOW = require('wowjs');
    window.wow = new WOW.WOW({
      live: false
    });
    window.wow.init();

    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  }, [scroll]);
  return (
    <header className={`main-header header-style-one ${scroll ? 'fixed-header' : ''}`}>
      {/* Header Top */}
      {screenType !== 'mobile' && (
        <div className="header-top">
          <div className="large-container">
            <div className="top-inner" style={{ justifyContent: 'end' }}>
              <ul className="info-list clearfix">
                <li>
                  <i className="icon-1"></i>
                  <Link to="mailto:support@silverbackmobile.com">support@silverbackmobile.com</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {/* Header lower */}
      <div className="header-lower">
        <Link to="/">
          <div
            className="large-container"
            // style={{ display: "flex", justifyContent: "right" }}
          >
            <div className="outer-box">
              <div className="logo-box">
                <div className="shape"></div>
                <figure className="logo">
                  <img src={headerlogo} alt="" width={'220px'} />
                </figure>
              </div>
            </div>
          </div>
        </Link>
      </div>
      {/*End Header lower*/}
      {/* Sticky Header  */}
      <div className={`sticky-header ${scroll ? 'animated slideInDown' : ''}`}>
        <div className="large-container">
          <div className="outer-box">
            <Link to="/">
              <div className="logo-box">
                <div className="shape"></div>
                <figure className="logo">
                  <img src={headerlogo} alt="" width={'220px'} />
                </figure>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}
