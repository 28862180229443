import React from 'react';
import image from 'assets/images/servicesDetails/security.jpeg';

import shapeImage from 'assets/images/shape/shape-11.png';

const CustomerSecurity = () => {
  return (
    <section className="about-style-two pt_20 pb_120">
      <div className="wow fadeInRightBig animated" data-wow-delay="00ms" data-wow-duration="1500ms">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_three">
                <div className="content-box mr_110">
                  <div className="sec-title mb_20">
                    <h6>Your Security</h6>
                    <h2>Keeping you protected, always.</h2>
                    <div className="text-box mb_40">
                      <p>
                        At Silverback, we recognize that protecting your hard-earned money is a
                        major priority. That is why we use industry-leading security measures to
                        protect your financial data and transactions.
                      </p>
                      <ul className="list-style-one clearfix">
                        <li>Multi-Factor Authentication.</li>
                        <li>Fraud Monitoring.</li>
                        <li>Advanced Encryption. </li>
                        <li>Dedicated customer support </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_two">
                <div className="image-box">
                  <div className="image-shape">
                    <div
                      className="shape-3"
                      style={{
                        backgroundImage: `url(${shapeImage})`
                      }}></div>
                    <div
                      className="shape-4"
                      style={{
                        backgroundImage: `url(${shapeImage})`
                      }}></div>
                  </div>
                  <figure className="grow image">
                    <img src={image} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(CustomerSecurity);
