import shapeImage from 'assets/images/shape/shape-11.png';
import aboutImageOne from 'assets/images/aboutUs/about-01.png';
import aboutImageTwo from 'assets/images/aboutUs/about-02.png';

export default function About() {
  return (
    <>
      <section className="about-style-two pt_80 pb_50">
        <div
          className="wow fadeInRightBig animated"
          data-wow-delay="00ms"
          data-wow-duration="1500ms">
          <div className="auto-container " style={{ transition: 'all 5000ms ease' }}>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content_block_three">
                  <div className="content-box mr_110">
                    <div className="sec-title mb_20">
                      <h6>ABOUT US</h6>
                      <h2>The Finance Guide on All Stage of Life.</h2>
                    </div>
                    <div className="text-box mb_40">
                      <p>
                        {`At Silverback, we're not just a neobank; we're your partner in financial
                        success. With a strong focus on wealth management and net worth services, we
                        empower our clients through financial education honed over 20 years in the
                        Fintech sector. Join us on the journey towards financial empowerment and
                        prosperity.`}
                      </p>
                      <ul className="list-style-one clearfix">
                        <li>Silverback Mission Statement</li>
                        <li>Wealth Insight Services</li>
                        <li>Net Worth</li>
                        <li>Account Services</li>
                        <li>Closing Remarks</li>
                      </ul>
                    </div>
                    {/* <div className="btn-box">
                    <Link href="/about" className="theme-btn btn-one">
                      Discover More
                    </Link>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image_block_two">
                  <div className="image-box">
                    <div className="image-shape">
                      <div
                        className="shape-1 "
                        style={{ backgroundImage: `url(${shapeImage})` }}></div>
                      <div
                        className="shape-2"
                        style={{ backgroundImage: `url(${shapeImage})` }}></div>
                    </div>
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                        <div className="image-inner">
                          <figure className="grow image mb_30">
                            <img src={aboutImageOne} alt="" />
                          </figure>
                          {/* <div
                          style={{
                            // backgroundImage: `url("${aboutImageOne}")`,
                            boxShadow: "inset 0px 0px 10px rgba(0,0,0,0.9)",
                          }}
                        >
                          <img src={aboutImageOne} alt="" />
                        </div> */}

                          <div className="experience-box">
                            <h2>
                              25<span>Years</span>
                            </h2>
                            <h5>of Experience in the Finance Service</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                        <div className="image-inner">
                          <figure className="grow image" style={{ paddingTop: '165px' }}>
                            <img src={aboutImageTwo} alt="" />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
