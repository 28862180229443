import React from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/press-release-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/press-release.png';
import { useEffect } from 'react';
import PressReleaseList from './section/pressReleaseList';
import { useResponsive } from 'component/useResponsive';

export default function MediaPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { screenType } = useResponsive();

  return (
    <>
      <TopHeader
        backgroundImage={screenType === 'mobile' ? mobileImage : desktopImage}
        title={'Press Release'}
      />
      <PressReleaseList />
      {/* <Subscribe /> */}
    </>
  );
}
