import React, { useEffect } from 'react';
import Banner from './sections/Banner';
import About from './sections/About';
import Services from './sections/services';
import CustomerSecurity from './sections/CustomerSecurity';
import App from './sections/App';

export default function LandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner />
      {/* <Features /> */}
      {/* <Discount /> */}
      {/* <OurProcess /> */}
      <Services />
      <About />
      {/* <Card /> */}
      <App />

      {/* <Exchange /> */}
      <CustomerSecurity />
      {/* <Subscribe /> */}
    </>
  );
}
