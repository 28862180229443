import React, { useEffect } from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/direct-deposit-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/direct-deposit.png';
import contentBgImage from 'assets/images/servicesDetails/direct-deposit.png';

import ServiceDetails from './serviceDetails';
import { useResponsive } from 'component/useResponsive';
export default function DirectDepositFeature() {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const masterCardFeatureData = {
    title: ' Direct Deposit Features',
    backgroundImage: screenType === 'mobile' ? mobileImage : desktopImage,
    contentBgImage: contentBgImage,
    content: [
      {
        title: 'Efficiency and Timeliness',
        desc: 'Direct deposit streamlines fund receipt by electronically transferring salaries or benefits into your bank account, ensuring availability on payday without the need for physical paychecks.'
      },
      {
        title: 'Security and Reliability',
        desc: 'Direct deposit enhances security and reliability by encrypting electronic transfers, minimizing the risk of lost or stolen paper checks and eliminating concerns about check fraud.'
      },
      {
        title: 'Automatic Budgeting and Savings',
        desc: 'With direct deposit, you can automatically allocate income to different accounts, fostering better budgeting and saving habits, effortlessly managing finances, and working towards financial goals.'
      }
    ]
  };

  return (
    <>
      <TopHeader
        backgroundImage={masterCardFeatureData?.backgroundImage}
        title={masterCardFeatureData?.title}
      />
      <ServiceDetails servicesData={masterCardFeatureData} />
      {/* <Subscribe /> */}
    </>
  );
}
