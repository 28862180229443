import React, { useEffect } from 'react';
import desktopImage from 'assets/images/banner/wealth-insight-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/wealth-management.png';
import TopHeader from 'component/pageHeader';
import WealthServices from './subComponent/wealthservices';
import AboutWealth from './subComponent/aboutWealth';
import { useResponsive } from 'component/useResponsive';
export default function WealthManagment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { screenType } = useResponsive();

  return (
    <>
      <TopHeader
        backgroundImage={screenType === 'mobile' ? mobileImage : desktopImage}
        title={'Wealth Managment'}
      />
      <AboutWealth />
      <WealthServices />
      {/* <Subscribe /> */}
    </>
  );
}
