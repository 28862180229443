import SVButton from 'component/SVButton';
import SVInput from 'component/SVInput';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { forgotPassWordApi } from 'store/slice/auth';
import { validateEmpty, validEmail } from 'utils/appConstant';

export default function ForgotPassWord() {
  const [signUpForm, setSignUpForm] = useState({});
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();

  const forgotPassWord = () => {
    if (validateEmpty(signUpForm.email) || !validEmail(signUpForm.email)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter valid email.'
      }));
      return;
    }
    var data = {
      email: signUpForm?.email
    };
    dispatch(forgotPassWordApi(data));
  };

  return (
    <div>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <h5>Forgot PassWord</h5>
      </div>
      <div className="mt-5">
        <SVInput
          text="text"
          name="email"
          icon="carbon:email"
          value={signUpForm?.email}
          placeholder="Email"
          onChange={(val) => {
            if (val) {
              setSignUpForm((signUpForm) => ({
                ...signUpForm,
                email: val.target.value
              }));
              if (val) {
                setFormError((formError) => ({
                  ...formError,
                  email: ''
                }));
              }
            }
          }}
          formError={formError?.email}
        />
      </div>

      <div style={{ marginTop: '2rem' }}>
        <SVButton title={'Submit'} onClick={forgotPassWord} />
      </div>
      <div>
        <div className="d-flex justify-content-center mt-2">
          <div className="textMuted">Have an account?</div> &nbsp;
          <Link to="/net-banking/login">
            <div className="link">Sign In</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
