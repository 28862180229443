import React, { useEffect } from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/about-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/about-us.png';
import AboutUsContent from './sections/aboutUs';
import { useResponsive } from 'component/useResponsive';

export default function AboutUS() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { screenType } = useResponsive();

  return (
    <>
      <TopHeader
        backgroundImage={screenType === 'mobile' ? mobileImage : desktopImage}
        title={'About Us'}
      />

      <AboutUsContent />
      {/* <Subscribe /> */}
    </>
  );
}
