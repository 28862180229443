import React, { useState } from 'react';
import {
  isObjectEmpty,
  isValidNumber,
  validateEmpty,
  validEmail
} from 'utils/appConstant';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { contantUs } from 'store/slice/contact';
import { SILVERBACK_API } from 'services/apiConstant';

export default function TechSupportForm() {
  const recaptchaRef = React.createRef();
  const dispatch = useDispatch();
  const [contactUsForm, setContactUsForm] = useState({});
  const [formError, setFormError] = useState({});
  const [captchaToken, setCaptchaToken] = useState('');

  const onCaptchaChange = (value) => {
    if (value) {
      setCaptchaToken(value)
      fetch(`${process.env.REACT_APP_API_URL}${SILVERBACK_API.verifyCaptcha}`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Data", data)
          setCaptchaToken(value);
        })
        .catch((error) => console.log(error));

    }
  };

  const btnSubmitInquiry = () => {
    if (isObjectEmpty(contactUsForm)) {
      setFormError((formError) => ({
        ...formError,
        name: 'Name field is required.',
        email: 'Email field is required.',
        phone: 'Phone field is required.',
        subject: 'This field is required.',
        description: 'This field is required.'
      }));
      return;
    }
    if (validateEmpty(contactUsForm.name)) {
      setFormError((formError) => ({
        ...formError,
        name: 'Name field is required.'
      }));
      return;
    }
    if (validateEmpty(contactUsForm?.email) || !validEmail(contactUsForm?.email)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter valid email.'
      }));
      return;
    }
    if (validateEmpty(contactUsForm?.email) || !isValidNumber(contactUsForm?.phone)) {
      setFormError((formError) => ({
        ...formError,
        phone: 'Please enter valid phone number.'
      }));
      return;
    }
    if (validateEmpty(contactUsForm?.subject)) {
      setFormError((formError) => ({
        ...formError,
        subject: 'Subject field is required.'
      }));
      return;
    }
    if (validateEmpty(contactUsForm?.description)) {
      setFormError((formError) => ({
        ...formError,
        message: 'Message field is required.'
      }));
      return;
    }
    if (!captchaToken) {
      setFormError((formError) => ({
        ...formError,
        captchaTokenErr: 'Please verify you are human.',
      }));
      return;
    }
    let request = {
      email: contactUsForm?.email,
      name: contactUsForm?.name,
      phone: contactUsForm?.phone,
      subject: `Support- ` + contactUsForm?.subject,
      description: contactUsForm?.description
    };
    dispatch(contantUs(request, setContactUsForm));
  };
  return (
    <section className="contact-section  pb_120">
      <div className="auto-container">
        <div className="sec-title centred mb_70">
          <h6>Inquire Now</h6>
          <h4>Find the answers you need. Contact us now.</h4>
        </div>
        <div className="form-inner">
          <div>All Fields are required*</div>
          <br />
          <div className="row clearfix">
            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
              <input
                type="text"
                name="username"
                placeholder="Your Name"
                required
                value={contactUsForm?.name || ''}
                onChange={(e) => {
                  setContactUsForm((contactUsForm) => ({
                    ...contactUsForm,
                    name: e.target.value
                  }));

                  setFormError((formError) => ({
                    ...formError,
                    name: ''
                  }));
                }}
              />
              <span style={{ color: 'red', fontSize: 13 }}>{formError.name}</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
              <input
                type="email"
                name="email"
                placeholder="Your email"
                value={contactUsForm?.email || ''}
                required
                onChange={(e) => {
                  setContactUsForm((contactUsForm) => ({
                    ...contactUsForm,
                    email: e.target.value
                  }));

                  setFormError((formError) => ({
                    ...formError,
                    email: ''
                  }));
                }}
              />
              <span style={{ color: 'red', fontSize: 13 }}>{formError.email}</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
              <input
                type="text"
                name="phone"
                required
                placeholder="Phone"
                value={contactUsForm?.phone || ''}
                maxLength={10}
                onChange={(e) => {
                  setContactUsForm((contactUsForm) => ({
                    ...contactUsForm,
                    phone: e.target.value
                  }));

                  setFormError((formError) => ({
                    ...formError,
                    phone: ''
                  }));
                }}
              />
              <span style={{ color: 'red', fontSize: 13 }}>{formError.phone}</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
              <input
                type="text"
                name="subject"
                required
                placeholder="Subject"
                value={contactUsForm?.subject || ''}
                onChange={(e) => {
                  setContactUsForm((contactUsForm) => ({
                    ...contactUsForm,
                    subject: e.target.value
                  }));

                  setFormError((formError) => ({
                    ...formError,
                    subject: ''
                  }));
                }}
              />
              <span style={{ color: 'red', fontSize: 13 }}>{formError.subject}</span>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <textarea
                name="message"
                placeholder="Type message"
                value={contactUsForm?.description || ''}
                onChange={(e) => {
                  setContactUsForm((contactUsForm) => ({
                    ...contactUsForm,
                    description: e.target.value
                  }));

                  setFormError((formError) => ({
                    ...formError,
                    message: ''
                  }));
                }}></textarea>
              <span style={{ color: 'red', fontSize: 13 }}>{formError.message}</span>
            </div>

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              onChange={onCaptchaChange}
              theme={'dark'}
            />
            <span style={{ color: 'red', fontSize: 13 }}>{formError.captchaTokenErr}</span>

            <br />

            <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
              <button className="theme-btn btn-one" onClick={btnSubmitInquiry} name="submit-form">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
