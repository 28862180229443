export const navConfig = [
  {
    title: 'Dashboard',
    path: '/user/dashboard',
    icon: 'material-symbols:dashboard'
  },
  {
    title: 'About',
    path: '/user/about',
    icon: 'ri:message-3-line'
  },
  {
    title: 'Cards',
    path: '/user/cards',
    icon: 'wpf:bank-cards'
  },
  {
    title: 'Net Worth',
    path: '/user/about',
    icon: 'healthicons:money-bag-outline'
  },
  {
    title: 'Wealth Insight',
    path: '/user/about',
    icon: 'ri:message-3-line'
  },
  {
    title: 'Contact Us',
    path: '/user/cards',
    icon: 'lucide:contact-round'
  }
];
