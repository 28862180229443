'use client';
import { Link } from 'react-router-dom';
import masterCradFeatureImage from '../../../../assets/images/directDeposit/service_mastercard.png';
import directDepositImage from '../../../../assets/images/directDeposit/service_direct_deposit.png';
import netWorthImage from '../../../../assets/images/directDeposit/service_networth.png';
import wealthImage from '../../../../assets/images/directDeposit/service_wealth.png';
import moneyTranferImage from '../../../../assets/images/directDeposit/service_transfer.png';
import rewardImage from '../../../../assets/images/directDeposit/service_rewards.png';

export default function Services() {
  const cardData = [
    {
      title: 'Mastercard Features',
      backgroundImage: masterCradFeatureImage,
      path: '/services/mastercard-feature',
      content: [
        {
          title: 'Convenience & Accessibility',
          desc: 'Mastercard debit cards offer global acceptance, ensuring easy access to funds for online, in-store, and ATM transactions worldwide.'
        },
        {
          title: 'Security Features',
          desc: 'Mastercard provides advanced security with EMV chip technology and Zero Liability Protection for prompt reporting of lost or stolen cards.'
        },
        {
          title: 'Rewards & Benefits',
          desc: ' Mastercard debit cards provide cashback, merchant discounts, travel insurance, and more, enhancing your overall spending experience.'
        }
      ]
    },
    {
      title: ' Direct Deposit Features',
      backgroundImage: directDepositImage,
      path: '/services/direct-deposit-feature',
      content: [
        {
          title: 'Efficiency & Timeliness',
          desc: 'Direct deposit streamlines fund receipt by electronically transferring salaries or benefits into your bank account, ensuring availability on payday without the need for physical paychecks.'
        },
        {
          title: 'Security & Reliability',
          desc: 'Direct deposit enhances security and reliability by encrypting electronic transfers, minimizing the risk of lost or stolen paper checks and eliminating concerns about check fraud.'
        },
        {
          title: 'Automatic Budgeting & Savings',
          desc: 'With direct deposit, you can automatically allocate income to different accounts, fostering better budgeting and saving habits, effortlessly managing finances, and working towards financial goals.'
        }
      ]
    },

    {
      title: 'Net Worth Services',
      backgroundImage: netWorthImage,
      path: '/services/net-worth-service',
      content: [
        {
          title: 'Comprehensive Evaluation',
          desc: ' Net worth services assess assets, liabilities, and financial health for a holistic understanding of overall wealth.'
        },
        {
          title: 'Strategic Asset Management',
          desc: 'Services optimize assets through investment planning, asset allocation, and risk management, aiming to boost net worth.'
        },
        {
          title: 'Personalized Financial Guidance',
          desc: ' Net worth services offer tailored advice on budgeting, savings, tax optimization, and wealth accumulation strategies for long-term financial success.'
        }
      ]
    },

    {
      title: ' Wealth Management',
      backgroundImage: wealthImage,
      path: '/services/wealth-managment-service',
      content: [
        {
          title: 'Holistic Financial Planning',
          desc: ' Wealth management services provide comprehensive financial planning, integrating investments, estate planning, and tax strategies for a complete approach.'
        },
        {
          title: 'Diversified Portfolio Management',
          desc: "Services focus on optimizing and diversifying investment portfolios to maximize returns and mitigate risks, aligning with clients' financial goals."
        },
        {
          title: 'Tailored Advisory Services',
          desc: 'Wealth management offers personalized advice, addressing individual financial needs, and providing ongoing guidance for informed decision-making and wealth growth.'
        }
      ]
    },

    {
      title: 'Rewards',
      backgroundImage: rewardImage,
      path: '/services/rewards',
      content: [
        {
          title: 'Cashback Rewards',
          desc: 'Earn cashback on eligible purchases with banking rewards, adding value to everyday spending.'
        },
        {
          title: 'Security and Reliability',
          desc: ' Accumulate bonus points for various transactions, redeemable for perks, discounts, or merchandise.'
        },
        {
          title: 'Automatic Budgeting and Savings',
          desc: 'Enjoy interest rate benefits on savings or other accounts as a banking reward, enhancing overall financial returns.'
        }
      ]
    },
    {
      title: 'Money/Internal Transfer',
      backgroundImage: moneyTranferImage,
      path: '/services/money-transfer-service',
      content: [
        {
          title: 'Instant Transfers',
          desc: 'Internal money transfers ensure immediate funds availability, facilitating quick transactions.'
        },
        {
          title: 'Cost-Efficient',
          desc: 'Transfer money internally without additional fees, saving on transaction costs.'
        },
        {
          title: 'Simplified Process',
          desc: ' Internal transfers offer a straightforward and user-friendly process, enhancing overall convenience.'
        }
      ]
    }
  ];

  return (
    <>
      <section className="service-style-three pt_80 pb_90">
        <div
          className="bg-layer"
          // style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h6>Our Services</h6>
            <h2>
              SilverBack <br />
              Account Services
            </h2>
          </div>
          <div className="row clearfix">
            {cardData.map((item, i) => {
              if (i >= 3) {
                return (
                  <div key={i} className="col-lg-4 col-md-6 col-sm-12 service-block">
                    <div
                      className="service-block-one wow fadeInDownBig animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms">
                      <div
                        className="inner-box"
                        style={{
                          background: `url("${item?.backgroundImage}")`,
                          backgroundRepeat: ' no-repeat',
                          backgroundSize: 'cover'
                        }}>
                        <h4>
                          <Link to="#">{item.title}</Link>
                        </h4>
                        <ul className="list-item clearfix">
                          {item.content.map((text, i) => {
                            return <li key={i}>{text.title}</li>;
                          })}
                        </ul>

                        <h6 className="view-more">
                          <Link to={item?.path}>View more</Link>
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={i} className="col-lg-4 col-md-6 col-sm-12 service-block">
                    <div
                      className="service-block-one wow  fadeInUpBig animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1500ms">
                      <div
                        className="inner-box"
                        style={{
                          background: `url("${item?.backgroundImage}")`,
                          backgroundRepeat: ' no-repeat',
                          backgroundSize: 'cover'
                        }}>
                        <h4>
                          <Link to="#">{item.title}</Link>
                        </h4>
                        <ul className="list-item clearfix">
                          {item.content.map((text, i) => {
                            return <li key={i}>{text.title}</li>;
                          })}
                        </ul>

                        <h6 className="view-more">
                          <Link to={item?.path}>View more</Link>
                        </h6>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    </>
  );
}
