import React from 'react';
import shapeImage from 'assets/images/shape/shape-11.png';

export default function ServiceDetails({ servicesData }) {
  return (
    <section className="about-style-two pt_120 pb_120">
      <div className="wow fadeInUpBig animated" data-wow-delay="00ms" data-wow-duration="1000ms">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_three">
                <div className="content-box mr_110">
                  <div className="sec-title mb_20">
                    <h6>{servicesData?.title}</h6>
                    {servicesData?.content.map((value) => {
                      return (
                        <>
                          <h2>{value.title}</h2>
                          <div className="text-box mb_40">
                            <p>{value.desc}</p>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_two">
                <div className="image-box">
                  <div className="image-shape">
                    <div
                      className="shape-3"
                      style={{
                        backgroundImage: `url(${shapeImage})`
                      }}></div>
                    <div
                      className="shape-4"
                      style={{
                        backgroundImage: `url(${shapeImage})`
                      }}></div>
                  </div>
                  <figure className="grow image">
                    <img src={servicesData?.contentBgImage} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
