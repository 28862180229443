import { Icon } from '@iconify/react';
import React from 'react';
import SideItem from './sideItem';
import { useResponsive } from 'component/useResponsive';
import bellIcon from '../../assets/images/SB-Icons/alarm-bell.svg';
import profile from '../../assets/images/SB-Icons/profile.png';
export default function AppBar({ title }) {
  const { screenType } = useResponsive();
  return (
    <>
      {screenType === 'desktop' ? (
        <div className="mt-5">
          <div className="d-flex justify-content-between">
            <div>{title}</div>
            <div className="d-flex gap-4">
              <div
                className="linearBackground d-flex justify-content-center align-items-center"
                style={{ height: '50px', width: '50px', borderRadius: 50 }}>
                <img src={bellIcon} alt="..." height={'40%'} width={'40%'} />
              </div>
              <div
                className="linearBackground d-flex justify-content-center align-items-center"
                style={{ height: '50px', width: '50px', borderRadius: 50 }}>
                <img src={profile} alt="..." height={'100%'} width={'100%'} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <nav
            className="navbar navbar-expand-lg navbar-light bg-black px-3"
            style={{ position: 'fixed', width: '100%', marginTop: '-0.99rem' }}>
            <button
              style={{ border: '1px solid white' }}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBackdrop"
              aria-controls="offcanvasWithBackdrop">
              <Icon icon={'bytesize:menu'} color="white" height={30} />
            </button>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-4">
                <div
                  className="linearBackground d-flex justify-content-center align-items-center"
                  style={{ height: '50px', width: '50px', borderRadius: 50 }}>
                  <img src={bellIcon} alt="..." height={'40%'} width={'40%'} />
                </div>
                <div
                  className="linearBackground d-flex justify-content-center align-items-center"
                  style={{ height: '50px', width: '50px', borderRadius: 50 }}>
                  <img src={profile} alt="..." height={'100%'} width={'100%'} />
                </div>
              </div>
            </div>
          </nav>

          <div
            className="offcanvas offcanvas-start"
            style={{ width: '70%' }}
            // tabIndex="-1"
            id="offcanvasWithBackdrop"
            aria-labelledby="offcanvasWithBackdropLabel">
            <SideItem />
          </div>
        </>
      )}
    </>
  );
}
