import React from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/rewards-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/Rewards.png';
import { useEffect } from 'react';
import RewardsDetail from './sections/rewardsDetail';
import { useResponsive } from 'component/useResponsive';
export default function Rewards() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { screenType } = useResponsive();

  return (
    <>
      <TopHeader
        backgroundImage={screenType === 'mobile' ? mobileImage : desktopImage}
        title={'SilverBack Rewards'}
      />
      <RewardsDetail />
      {/* <Subscribe /> */}
    </>
  );
}
