import React from 'react';
import ComingSoonHeader from './comingSoonHeader';
import CominSoonFooter from './comingSoonFooter';
import TermsAndCondition from 'pages/landingPage/termsAndCondition';

export default function ComingSoonTerms() {
  return (
    <>
      <div className={`boxed_wrapper ltr`} style={{ background: 'black' }}>
        <ComingSoonHeader />
        <TermsAndCondition />
        <CominSoonFooter />
      </div>
    </>
  );
}
