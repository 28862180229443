import React from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/networth-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/net-worth-services.png';
import contentBgImage from 'assets/images/servicesDetails/networth.png';
import ServiceDetails from './serviceDetails';
import { useEffect } from 'react';
import { useResponsive } from 'component/useResponsive';
export default function NetWorthService() {
  const { screenType } = useResponsive();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const masterCardFeatureData = {
    title: 'Net Worth Services ',
    backgroundImage: screenType === 'mobile' ? mobileImage : desktopImage,
    contentBgImage: contentBgImage,
    content: [
      {
        title: 'Comprehensive Evaluation',
        desc: ' Net worth services assess assets, liabilities, and financial health for a holistic understanding of overall wealth.'
      },
      {
        title: 'Strategic Asset Management',
        desc: 'Services optimize assets through investment planning, asset allocation, and risk management, aiming to boost net worth.'
      },
      {
        title: 'Personalized Financial Guidance',
        desc: ' Net worth services offer tailored advice on budgeting, savings, tax optimization, and wealth accumulation strategies for long-term financial success.'
      }
    ]
  };

  return (
    <>
      <TopHeader
        backgroundImage={masterCardFeatureData?.backgroundImage}
        title={masterCardFeatureData?.title}
      />
      <ServiceDetails servicesData={masterCardFeatureData} />
      {/* <Subscribe /> */}
    </>
  );
}
