import React, { useState } from 'react';
import SVInput from 'component/SVInput/index';
import SVButton from 'component/SVButton/index';
import { isObjectEmpty, validateEmpty, validEmail } from 'utils/appConstant';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userLogin } from 'store/slice/auth';

export default function Login() {
  const [loginForm, setLoginForm] = useState({});
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginSubmit = () => {
    const digit = /(?=.*[0-9])/;
    const specialChar = /[ -/:-@[-`{-~]/;
    if (isObjectEmpty(loginForm)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Email field is required.',
        password: 'Password field is required.'
      }));
      return;
    }

    if (validateEmpty(loginForm.email) || !validEmail(loginForm.email)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter valid email.'
      }));
      return;
    }

    if (validateEmpty(loginForm.password)) {
      setFormError((formError) => ({
        ...formError,
        password: 'Please enter password'
      }));
      return;
    }
    if (loginForm.password.search(/[a-z]/) == -1) {
      setFormError((formError) => ({
        ...formError,
        password: 'Your password needs at least one lower case letter'
      }));
      return;
    }
    if (loginForm.password.search(/[A-Z]/) == -1) {
      setFormError((formError) => ({
        ...formError,
        password: 'Your password needs at least one upper case letter'
      }));
      return;
    }
    if (loginForm.password?.length < 8) {
      setFormError((formError) => ({
        ...formError,
        password: 'Password length must be at least 8 characters'
      }));
      return;
    }
    if (!digit.test(loginForm.password) && !specialChar.test(loginForm.password)) {
      setFormError((formError) => ({
        ...formError,
        password: 'Please enter one number or symbol'
      }));
      return;
    }
    var data = {
      email: loginForm.email
    };
    let formData = new FormData();
    formData.append('username', loginForm.email);
    formData.append('password', loginForm.password);
    dispatch(userLogin(formData, data, navigate));
  };
  return (
    <div>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <h5>Sign In To Your Account</h5>
      </div>
      <div className="mt-5">
        <SVInput
          text="text"
          name="email"
          icon="carbon:email"
          value={loginForm?.email}
          placeholder="Email"
          onChange={(val) => {
            if (val) {
              setLoginForm((loginForm) => ({
                ...loginForm,
                email: val.target.value
              }));
              if (val) {
                setFormError((formError) => ({
                  ...formError,
                  email: ''
                }));
              }
            }
          }}
          formError={formError?.email}
        />
      </div>

      <div className="mt-3">
        <SVInput
          icon="mdi:password"
          type={loginForm?.showPass ? 'text' : 'password'}
          name="password"
          showPassClick={() =>
            setLoginForm((loginForm) => ({
              ...loginForm,
              showPass: !loginForm?.showPass
            }))
          }
          placeholder="Password"
          endIcon={loginForm?.showPass ? 'eva:eye-outline' : 'tabler:eye-off'}
          value={loginForm?.password}
          required
          onChange={(val) => {
            if (val) {
              setLoginForm((loginForm) => ({
                ...loginForm,
                password: val.target.value
              }));
              if (val) {
                setFormError((formError) => ({
                  ...formError,
                  password: ''
                }));
              }
            }
          }}
          formError={formError?.password}
        />
      </div>
      <div style={{ marginTop: 5, textAlign: 'right' }}>
        <Link to="/net-banking/forgot-password">
          <div className="link">Forgot Password?</div>
        </Link>
      </div>
      <div className="mt-5">
        <SVButton onClick={loginSubmit} title={'Sign In'} />
      </div>
      <div>
        <div className="d-flex justify-content-center mt-2">
          <div className="textMuted">{` Don't have an account?`}</div> &nbsp;
          <Link to="/net-banking/sign-up">
            <div className="link">Sign Up</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
