import { createSlice } from '@reduxjs/toolkit';
import { errorToast, successToast } from 'component/toast';
import { api } from 'services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';

const slice = createSlice({
  name: 'contact',
  initialState: {},
  reducers: {}
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

// ----subscribe SilverBack-------------

export const subscribeUs = (data, setEmail) => async () => {
  // dispatch(loadingSuccess(true));
  try {
    await api.post(SILVERBACK_API.subscribeUs, data).then((response) => {
      console.log('response', response);
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast('success', response.data.message, 'suppport');
        setEmail('');
      } else {
        errorToast('error', response.data.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const contantUs = (data, setContactUsForm) => async () => {
  // dispatch(loadingSuccess(true));
  try {
    await api.post(SILVERBACK_API.contantUs, data).then((response) => {
      console.log('response', response);
      if (response.status === STATUS_CODES.SUCCESS) {
        successToast('success', response.data.message, 'suppport');
        setContactUsForm({});
      } else {
        errorToast('error', response.data.detail, 'support');
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
