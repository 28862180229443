import React, { useEffect } from 'react';

export default function TermsAndCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const termsOfUseData = [
    {
      title: 'General Terms of Use:',
      detail:
        'By accessing this site or using the services, you agree to these Terms of Use. If you disagree, please do not proceed.',
      detailTwo:
        "You're viewing a page of the Silverback Corp website or a related site or app (“Site”). These Terms of Use govern your access and use of the Site and Services. The Site is for individuals at least 13 years old. If you're under 13, please don't use this Site. ",
      detailThree:
        'Silverback Corp can modify these Terms of Use at any time without notice. Your continued use after any changes means you agree to the updated terms.'
    },
    {
      title: 'Trademarks, Copyrights, and Use Restrictions:      ',
      detail:
        "All trademarks and content are owned by Silverback Corp or its licensors. You can't use Silverback Corp trademarks without permission.",
      detailTwo:
        "The Site's content is owned or licensed by Silverback Corp and is protected by intellectual property laws. You can't copy, distribute, or modify the content without permission. "
    },
    {
      title: 'Your Use of Services:',
      detail:
        "You agree not to misuse the Services. This includes not overloading our systems, restricting others' use, or transmitting harmful material.      ",
      detailTwo: "You'll only use the Services for lawful purposes and won't violate any laws."
    },
    {
      title: 'Claims of Copyright Infringement:',
      detail:
        'If you believe materials on the Site infringe your copyright, you can request removal. Learn more at the U.S. Copyright Office site.'
    },
    {
      title: 'Links to Third-Party Sites:',
      detail:
        "We're not responsible for third-party sites linked from the Site. Use them at your own risk and follow their terms."
    },
    {
      title: 'Registration Information:',
      detail: 'Provide accurate information when registering on the Site.'
    },
    {
      title: 'Privacy:',
      detail:
        "Your use of the Site is subject to Silverback Corp's privacy policies. By using the Site, you consent to data collection."
    },
    {
      title: 'Feedback:',
      detail: 'Any feedback you provide may be used by Silverback Corp without restriction.'
    },
    {
      title: 'Chat:',
      detail: "Chat service availability isn't guaranteed. If you're under 13, you can't use it."
    },
    {
      title: 'Access Codes:',
      detail: 'Access codes sent to you are for your use only.'
    },
    {
      title: 'Disclaimer and Limitation of Liability:',
      detail:
        "The Services are provided “as is,” and we aren't liable for any damages from using them."
    },
    {
      title: 'Indemnification:',
      detail:
        "You'll defend and indemnify Silverback Corp from any claims related to your misuse of the Services or Site."
    },
    {
      title: 'Enforceability and Governing Law:',
      detail:
        'These Terms of Use are governed by Minnesota law. If any part is unenforceable, the remaining terms apply.',
      detailTwo:
        "The Site is hosted in the U.S. If you're outside the U.S., you're subject to U.S. law. Your use of the Site means you consent to data transfer to the U.S. and U.S. law applies.        "
    }
  ];
  return (
    <>
      <section className="service-style-three pt_80 pb_90">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            {/* <h6>Privacy & Security</h6> */}
            <h2>Terms & Condition</h2>
          </div>
          {termsOfUseData?.map((data, index) => {
            return (
              <div className="sec-title mb_20 pt-3" key={index}>
                <h6>{data?.title}</h6>
                <div className="detail-text">{data?.detail}</div>
                {data?.detailTwo && <div className="detail-text pt-3">{data?.detailTwo}</div>}
                {data?.detailThree && <div className="detail-text pt-3">{data?.detailThree}</div>}
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
