import React from 'react';

export default function SVButton(props) {
  const { style, onClick, title,background } = props;
  return (
    <div>
      <button
        onClick={onClick}
        style={{
          ...style,
          borderRadius: 35,
          width: '100%',
          minHeight:30,
          fontWeight: 600,
          fontSize: 17,
          letterSpacing: 1.5,
          paddingLeft:'2rem',
          paddingRight:'2rem',
          background: background ? background : '#0887fc',
          color: 'white'
        }}
        type="submit"
        className="btn btn-lg"
        // className="theme-btn btn-one"
      >
        {title}
      </button>
    </div>
  );
}
