import React, { useEffect } from 'react';

export default function PrivacyPolicies() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="service-style-three pt_80 pb_90">
        <div className="auto-container">
          <div className="sec-title centred mb_60">
            <h2>Privacy Policy</h2>
          </div>
          <div className="sec-title mb_20 pt-3">
            <div className="detail-text">
              {
                "Your privacy matters to us. We consistently review and uphold strict privacy standards to safeguard the personal information we gather, use, and share. This U.S. Online Privacy Notice ('Notice') outlines how Silverbank Corp and its affiliates handle your personal information when you engage with us online via our websites, event registration sites, mobile applications, and social platforms"
              }
            </div>
            <h6 className="pt-5"> This Notice covers:</h6>
            <div className="d-flex pt-2">
              <h6>1.</h6>&nbsp;&nbsp;
              <div className="detail-text">
                Collection of personal information during your online interactions with us,
                including through our advertisements displayed on online services operated by us or
                non-affiliated third parties.
              </div>
            </div>
            <div className="d-flex py-4">
              <h6>2.</h6>&nbsp;&nbsp;
              <div className="detail-text">
                Use or sharing of collected personal information for providing products and services
                to you, as well as for advertising or event management purposes.
              </div>
            </div>
            <div className="detail-text">
              {`  References to "Silverbank Corp" or "we," "us," or "our" in this Notice include banking
              and non-banking U.S. affiliates or subsidiaries of Silverbank Corporation that link to
              or reference this Notice.`}
            </div>
            <div className="detail-text py-4">
              By using our Sites and Mobile Apps, you agree to the terms of this Notice. Our U.S.
              online services covered by this Notice are intended for a U.S. audience. If you use
              our online services outside the U.S., please review the privacy policy applicable to
              that service to understand how your information is handled.
            </div>
            <div className="detail-text">
              Silverbank Corp collaborates with third-party providers obligated to adhere to our
              policies for information protection. However, if you visit or use third-party provider
              sites or mobile apps, please review their online privacy practices to understand how
              your personal information may be collected, used, and shared.
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
