import { createRef } from 'react';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { SILVERBACK_API } from 'services/apiConstant';
import { contantUs } from 'store/slice/contact';
import {
  isObjectEmpty,
  isValidNumber,
  validEmail,
  validateEmpty
} from 'utils/appConstant';

const faq = [
  {
    id: 0,
    ques: 'What is a Neobank, and how does Silverback differ from traditional banks?',
    ans: 'A Neobank like Silverback operates entirely online, offering innovative financial services without physical branches. We focus on net worth management, wealth management, and standard banking functions, providing greater accessibility and efficiency compared to traditional banks.'
  },
  {
    id: 1,
    ques: 'What is net worth management, and how can Silverback assist in managing it?',
    ans: 'Net worth management involves tracking and optimizing your assets and liabilities. Silverback offers tools and services to help you monitor your net worth, including investment tracking, debt management, and financial planning assistance.'
  },
  {
    id: 2,
    ques: 'What wealth management services does Silverback offer?',
    ans: 'Silverback provides personalized wealth management solutions tailored to your financial goals and risk tolerance. Our services include investment advice, portfolio management, retirement planning, estate planning, and more.'
  },
  {
    id: 3,
    ques: 'How does Silverback ensure the security of my funds and personal information?',
    ans: 'Silverback employs state-of-the-art security measures, including encryption, multi-factor authentication, and regular security audits, to safeguard your funds and sensitive information against unauthorised access and cyber threats.'
  },
  {
    id: 4,
    ques: 'What standard banking functions does Silverback offer?',
    ans: 'Silverback offers essential banking services such as checking accounts, debit cards, direct deposit, bill pay, mobile banking, and peer-to-peer payments, providing you with convenient and reliable banking solutions.'
  },
  {
    id: 5,
    ques: 'Can I access my Silverback account from anywhere?',
    ans: 'Yes, you can access your Silverback account from anywhere with an internet connection using our web platform or mobile app, allowing you to manage your finances on the go.'
  },
  {
    id: 6,
    ques: 'Does Silverback charge any fees for its services?',
    ans: 'Silverback strives to offer transparent and competitive pricing with minimal fees. However, certain services may incur fees, such as overdrafts, wire transfers, or expedited transactions. We provide clear fee disclosures to ensure you understand any charges associated with our services.'
  },
  {
    id: 7,
    ques: 'How can I open an account with Silverback?',
    ans: 'pening an account with Silverback is quick and easy. Simply visit our website or download our mobile app, complete the online application, and verify your identity. Once approved, you can start enjoying our comprehensive financial services.'
  },
  {
    id: 8,
    ques: 'Does Silverback offer customer support?',
    ans: 'Yes, Silverback provides dedicated customer support to assist you with any inquiries or issues you may encounter. Our knowledgeable representatives are available via phone, email, or live chat to provide timely assistance and support.'
  },
  {
    id: 9,
    ques: 'Is my money FDIC insured with Silverback?',
    ans: 'Yes, your deposits with Silverback are FDIC insured up to the maximum allowable limit, providing you with peace of mind and protection against bank failures'
  }
];
export default function FAQ() {
  const [isActiveId, setIsActiveId] = useState(null);
  const recaptchaRef = createRef();
  const dispatch = useDispatch();
  const [getYourAnsForm, setGetYourAnsForm] = useState({});
  const [formError, setFormError] = useState({});
  const [captchaToken, setCaptchaToken] = useState('');

  const onCaptchaChange = (value) => {
    if (value) {
      setCaptchaToken(value);
      fetch(SILVERBACK_API.verifyCaptch, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate'
        }
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => console.log(error));
    }
  };

  const btnSubmitInquiry = () => {
    if (isObjectEmpty(getYourAnsForm)) {
      setFormError((formError) => ({
        ...formError,
        name: 'Name field is required.',
        email: 'Email field is required.',
        phone: 'Phone field is required.',
        message: 'This field is required.'
      }));
      return;
    }
    if (validateEmpty(getYourAnsForm.name)) {
      setFormError((formError) => ({
        ...formError,
        name: 'Name field is required.'
      }));
    }
    if (validateEmpty(getYourAnsForm.email) || !validEmail(getYourAnsForm.email)) {
      setFormError((formError) => ({
        ...formError,
        email: 'Please enter valid email.'
      }));
    }
    if (validateEmpty(getYourAnsForm.email) || !isValidNumber(getYourAnsForm.phone)) {
      setFormError((formError) => ({
        ...formError,
        phone: 'Please enter valid phone number.'
      }));
    }
    if (validateEmpty(getYourAnsForm.message)) {
      setFormError((formError) => ({
        ...formError,
        message: 'Message field is required.'
      }));
    }
    if (!captchaToken) {
      setFormError((formError) => ({
        ...formError,
        captchaTokenErr: 'Please verify you are human.',
      }));
      return;
    }
    let request = {
      email: getYourAnsForm?.email,
      name: getYourAnsForm?.name,
      phone: getYourAnsForm?.phone,
      subject: `FAQ- ` + getYourAnsForm?.subject,
      description: getYourAnsForm?.description
    };
    dispatch(contantUs(request, setGetYourAnsForm));
  };

  const handleToggle = (key) => {
    console.log('key', key);
    if (key === isActiveId) {
      setIsActiveId(null);
    } else {
      setIsActiveId(key);
    }
  };
  return (
    <>
      <div>
        <section className="faq-section pt_40 pb_100">
          <div className="auto-container">
            <div className="sec-title centred mb_70">
              <h6>Regular FAQ’S</h6>
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className="row clearfix">
              {faq.map((data) => {
                return (
                  <div className="col-lg-6 col-md-12 col-sm-12 accordion-column" key={data?.id}>
                    <div
                      className="service-block-one wow fadeInUpBig animated"
                      data-wow-delay="00ms"
                      data-wow-duration="1000ms">
                      <ul className="accordion-box">
                        {/*Accordion Block*/}
                        <li className="accordion block">
                          <div
                            className={isActiveId === data?.id ? 'acc-btn active' : 'acc-btn'}
                            onClick={() => handleToggle(data?.id)}>
                            <div className="icon-box"></div>
                            <h4>{data.ques}</h4>
                          </div>
                          <div
                            className={
                              isActiveId === data?.id ? 'acc-content current' : 'acc-content'
                            }>
                            <div className="content">
                              <div className="text">
                                <p style={{ color: '#000' }}>{data.ans}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* faq-form-section end */}
        <section className="contact-section  pb_120">
          <div className="auto-container">
            <div className="sec-title centred mb_70">
              <h6>Asked Question</h6>
              <h2>Get Your Answer</h2>
            </div>
            <div className="form-inner">
              <div>All Fields are required*</div>
              <br />
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    type="text"
                    name="username"
                    placeholder="Your Name"
                    required
                    value={getYourAnsForm?.name || ''}
                    onChange={(e) => {
                      setGetYourAnsForm((getYourAnsForm) => ({
                        ...getYourAnsForm,
                        name: e.target.value
                      }));

                      setFormError((formError) => ({
                        ...formError,
                        name: ''
                      }));
                    }}
                  />
                  <span style={{ color: 'red', fontSize: 13 }}>{formError.name}</span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Your email"
                    required
                    value={getYourAnsForm?.email || ''}
                    onChange={(e) => {
                      setGetYourAnsForm((getYourAnsForm) => ({
                        ...getYourAnsForm,
                        email: e.target.value
                      }));
                      setFormError((formError) => ({
                        ...formError,
                        email: ''
                      }));
                    }}
                  />
                  <span style={{ color: 'red', fontSize: 13 }}>{formError.email}</span>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <input
                    type="text"
                    name="phone"
                    required
                    placeholder="Phone Number"
                    maxLength={10}
                    value={getYourAnsForm?.phone || ''}
                    onChange={(e) => {
                      setGetYourAnsForm((getYourAnsForm) => ({
                        ...getYourAnsForm,
                        phone: e.target.value
                      }));

                      setFormError((formError) => ({
                        ...formError,
                        phone: ''
                      }));
                    }}
                  />
                  <span style={{ color: 'red', fontSize: 13 }}>{formError.phone}</span>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <textarea
                    name="message"
                    placeholder="Type message"
                    value={getYourAnsForm?.message || ''}
                    onChange={(e) => {
                      setGetYourAnsForm((getYourAnsForm) => ({
                        ...getYourAnsForm,
                        message: e.target.value
                      }));

                      setFormError((formError) => ({
                        ...formError,
                        message: ''
                      }));
                    }}></textarea>
                  <span style={{ color: 'red', fontSize: 13 }}>{formError.message}</span>
                </div>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY
}
                  onChange={onCaptchaChange}
                  theme={'dark'}
                />
                <span style={{ color: 'red', fontSize: 13 }}>{formError.captchaTokenErr}</span>

                <br />

                <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                  <button
                    className="theme-btn btn-one"
                    onClick={btnSubmitInquiry}
                    name="submit-form">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* faq-form-section end */}
      </div>
    </>
  );
}
