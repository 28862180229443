import { createSlice } from '@reduxjs/toolkit';
import { errorToast, successToast } from 'component/toast';
import secureLocalStorage from 'react-secure-storage';
import { api, apiFormData } from 'services';
import { SILVERBACK_API, STATUS_CODES } from 'services/apiConstant';

const slice = createSlice({
  name: 'auth',
  initialState: {},
  reducers: {}
});

export default slice.reducer;

/**********************ACTIONS************************************************ */

export const userLogin = (requestParams, data, navigate) => async (dispatch) => {
  apiFormData
    .post(`${SILVERBACK_API.auth.login}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        secureLocalStorage.setItem('userData', JSON.stringify(data));
        console.log(response?.data?.data?.isEmailVerified, 'atp');
        if (response?.data?.data?.isEmailVerified) {
          successToast('success', response.data.message, 'suppport');
          secureLocalStorage.setItem(process.env.REACT_APP_USER_AUTH, JSON.stringify(response.data));
          navigate('/user/dashboard');
        } else if (response?.data?.data?.isEmailVerified === false) {
          dispatch(sendOtp(data));
          navigate('/net-banking/otp');
        } else {
          navigate('/');
        }
      } else if (response.status == STATUS_CODES.FAILURE) {
        errorToast('error', response.data.detail, 'support');
      }
      // dispatch(loadingSuccess(false));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const verifyOtpApi = (requestParams, navigate) => async () => {
  try {
    await api.post(SILVERBACK_API.auth.verifyOtpApi, requestParams).then((response) => {
      let result = response.data;
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(result.message);
        secureLocalStorage.clear('userData');
        secureLocalStorage.setItem(process.env.REACT_APP_USER_AUTH, JSON.stringify(response.data));
        navigate('/user/dashboard');
      } else {
        errorToast(result.detail);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const sendOtp = (requestParams) => async () => {
  try {
    await api.post(SILVERBACK_API.auth.sendOtp, requestParams).then((response) => {
      let result = response.data;
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast(result.message);
        // secureLocalStorage.setItem(
        //   STORAGE_KEY.USER_AUTH,
        //   JSON.stringify(response.data)
        // );
      } else {
        errorToast(result.detail);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const signUpUser = (requestParams, navigate) => async () => {
  console.log(navigate);
  api
    .post(`${SILVERBACK_API.auth.signUp}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast('success', response.data.message, 'suppport');
        secureLocalStorage.setItem('userData', JSON.stringify({ email: requestParams?.email }));
        navigate('/net-banking/otp');
      } else if (response.status == STATUS_CODES.FAILURE) {
        errorToast('error', response.data.detail, 'support');
      }
      // dispatch(loadingSuccess(false));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const forgotPassWordApi = (requestParams, navigate) => async () => {
  console.log(navigate);
  api
    .post(`${SILVERBACK_API.auth.forgotPassWord}`, requestParams)
    .then((response) => {
      if (response.status == STATUS_CODES.SUCCESS) {
        successToast('success', response.data.message, 'suppport');
      } else if (response.status == STATUS_CODES.FAILURE) {
        errorToast('error', response.data.detail, 'support');
      }
      // dispatch(loadingSuccess(false));
    })
    .catch((e) => {
      console.log(e);
    });
};
