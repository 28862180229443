import React, { useEffect } from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/networth-banner-bg.png';
import AboutNetWorth from './subComponent/aboutNetWorth';
import { useResponsive } from 'component/useResponsive';
import mobileImage from 'assets/images/mobileBanners/net-worth-services.png';
export default function NetWorth() {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log('screenType', screenType);
  return (
    <>
      <TopHeader
        backgroundImage={screenType === 'mobile' ? mobileImage : desktopImage}
        title={'Net Worth'}
      />
      <AboutNetWorth />
      {/* <Subscribe /> */}
    </>
  );
}
