import React, { useEffect } from 'react';
import TopHeader from 'component/pageHeader';
import desktopImage from 'assets/images/banner/master-card-banner-bg.png';
import mobileImage from 'assets/images/mobileBanners/mastercard-features.png';
import contentBgImage from 'assets/images/servicesDetails/master-card.png';
import ServiceDetails from './serviceDetails';
import { useResponsive } from 'component/useResponsive';
export default function MasterCardFeature() {
  const { screenType } = useResponsive();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const masterCardFeatureData = {
    title: 'Mastercard Features',
    backgroundImage: screenType === 'mobile' ? mobileImage : desktopImage,
    contentBgImage: contentBgImage,
    content: [
      {
        title: 'Convenience and Accessibility',
        desc: 'Mastercard debit cards offer global acceptance, ensuring easy access to funds for online, in-store, and ATM transactions worldwide.'
      },
      {
        title: 'Security Features',
        desc: 'Mastercard provides advanced security with EMV chip technology and Zero Liability Protection for prompt reporting of lost or stolen cards.'
      },
      {
        title: 'Rewards and Benefits',
        desc: ' Mastercard debit cards provide cashback, merchant discounts, travel insurance, and more, enhancing your overall spending experience.'
      }
    ]
  };
  return (
    <>
      <TopHeader
        backgroundImage={masterCardFeatureData?.backgroundImage}
        title={masterCardFeatureData?.title}
      />
      <ServiceDetails servicesData={masterCardFeatureData} />
      {/* <Subscribe /> */}
    </>
  );
}
