import React from 'react';
import ComingSoonHeader from './comingSoonHeader';
import CominSoonFooter from './comingSoonFooter';
import PrivacyPolicies from 'pages/landingPage/disclaimer/privacyPolicy';

export default function ComingSoonPrivacy() {
  return (
    <>
      <div className={`boxed_wrapper ltr`} style={{ background: 'black' }}>
        <ComingSoonHeader />
        <PrivacyPolicies />
        <CominSoonFooter />
      </div>
    </>
  );
}
